$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/grid";
@import "./src/styles/modal";

.card-error-modal {
  .modal-box {
    min-width: 25rem;
    min-height: 20rem;
    // .input-link {
    //   background-color: #f8f7f7;
    //   padding: 0.3rem 0.5rem;
    //   font-size: 0.7rem;
    //   font-weight: 50;
    //   width: 17rem;
    //   height: 2.3rem;
    // }

    // .button-copy-url {
    //   background: $grey-color;
    //   border-radius: 3px;
    //   padding: 0.3rem 0.5rem;
    //   font-size: 0.8rem;
    //   color: $font-primary;
    //   width: 7rem;
    //   height: 2.3rem;
    //   margin-left: -1px;
    // }
  }
}
