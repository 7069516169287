$app_name:hero;
@import "../../../styles/variables";

.form-input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border: 1px solid rgba($color: #cccccc, $alpha: 0.5);
  outline: none;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 1rem;
  line-height: 1.5;
  display: block;
  transition: all 0.1s ease-in;

  &:read-only {
    background-color: rgba($color: #cccccc, $alpha: 0.1);
    cursor: not-allowed;
  }

  &:focus {
    border-color: $primary-color;
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba($color: $primary-color, $alpha: 0.3);
  }

  &.has-error {
    border-color: $error-color;
    &:focus {
      border-color: $error-color;
      outline: 0;
      box-shadow: 0 0 0 0.1rem rgba($color: $error-color, $alpha: 0.3);
    }
  }

  &-error {
    color: $error-color;
    font-size: 0.8rem;
    display: flex;
    align-items: center;

    & i {
      font-size: 1rem;
      margin-right: 5px;
    }
  }

  &-help-text {
    color: $font-secondary;
    font-size: 0.8rem;
  }

  &.chassis-number {
    text-transform: uppercase;
  }

  // &:hover {
  //     border: 1px solid $primary-color;
  // }
}
