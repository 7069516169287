$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/mixin";

.section-form {
  &-header {
    background-color: #f1f2f3;
    border-radius: $radius;
    padding: 4px 8px;
    font-size: 12px;
    color: #646e78;
    display: flex;
    align-items: center;

    span {
      font-size: 1rem;
    }
  }

  &-content {
    padding: $padding-space / 2;
  }
}
