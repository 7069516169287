$app_name:hero;
.homepage-dashboard-section {
  position: relative;
  padding-top: 80px;
  z-index: 1;
  text-align: center;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 60%;
    width: 100%;
    border-top-left-radius: 120px;
    border-top-right-radius: 120px;
    background: transparent linear-gradient(180deg, #e6007e 0%, #ce4aab 100%) 0%
      0% no-repeat padding-box;
    z-index: -10;
    background-attachment: fixed;

    @media (max-width: 1024px) {
      height: 40%;
      border-top-left-radius: 80px;
      border-top-right-radius: 80px;
    }
  }

  &-box {
    width: 100%;
    min-height: 1440px;
    position: relative;
    margin: auto;
  }

  .o3dball-1 {
    position: absolute;
    z-index: 0;
    top: -60px;
    right: 0;
    width: 300px;
    height: 600px;

    background-image: url("../../../../../assets/homepage/3dballs-1.svg");
    background-repeat: no-repeat;
    background-position-x: 0;

    @media (min-width: 1600px) {
      right: 0px;
    }
    @media (min-width: 1300px) {
      right: -100px;
    }
  }

  .o3dball-2 {
    position: absolute;
    z-index: 0;
    left: 0;
    width: 285px;
    height: 600px;
    background-image: url("../../../../../assets/homepage/3dballs-2.svg");
    background-repeat: no-repeat;
    background-position-x: 0;
  }

  .dashboard-img {
    @media (min-width: 1280px) {
      max-width: 80%;
    }
    @media (min-width: 1440px) {
      max-width: 80%;
    }

    @media (min-width: 1600px) {
      max-width: 100%;
    }
  }
}
