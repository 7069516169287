$app_name:hero;
.clip-circle {
  background-image: url("../../../../../assets/homepage/bg-city2.svg");
  background-size: cover;
  background-position-x: center;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  background-repeat: no-repeat;
  background-color: #efefed;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  overflow: hidden;

  &-mobile {
    &::before {
      content: "";
      background-image: url("../../../../../assets/homepage/bg-city2.svg");
      background-size: 70%;
      background-position-x: center;
      background-position-y: bottom;
      border-bottom-left-radius: 50%;
      border-bottom-right-radius: 50%;
      background-repeat: no-repeat;
      background-color: #efefed;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 200%;
      height: 100%;
      overflow: hidden;
    }
  }
}

.hero-highlight {
  position: relative;
  z-index: 1;
  display: inline-block;
  &::before {
    content: "";
    @apply bg-secondary-1 absolute -z-10;
    border-radius: 20px;
    bottom: 6px;
    @apply h-12 lg:h-2;
    width: 105%;
    animation-name: animate;
    animation-duration: 0.6s;
    left: 50%;
    transform: translateX(-50%);
  }
}

@keyframes animate {
  0% {
    bottom: 20px;
    opacity: 0;
  }

  100% {
    opacity: 1;
    bottom: 10px;
  }
}
