$app_name:hero;
.compulsory-page {
  padding: 16px;

  &-header {
    &-grid {
      display: grid;
      grid-template-columns: 150px 1fr;

      div {
        margin-bottom: 0.5rem;
      }
    }
  }
  &-detail {
    h4 {
      background-color: #f1f2f3;
      padding: 4px 8px;
    }

    ul {
      margin-top: 8px;
      margin-bottom: 16px;
      padding-left: 20px;

      li {
        display: grid;
        padding: 8px 0;
        grid-template-columns: 1fr 50%;
        border-bottom: solid 1px #f5f5ff;
      }
    }
  }
}
