.highlight-2xl,.highlight-3xl {
  position: relative;
  z-index: 1;
}

.highlight-2xl:after {
  @apply absolute bg-secondary rounded-20;
  content: '';
  transform: translateX(-50%) translateY(50%);
  left: 50%;
  height: 10px;
  width: 110%;
  bottom: 0;
  z-index: -1;
}

.highlight-3xl:after {
  @apply absolute bg-secondary rounded-20;
  content: '';
  transform: translateY(-50%) translateY(50%);
  left: 50%;
  height: 24px;
  width: 110%;
  bottom: 0;
  z-index: -1;
}

