$app_name:hero;
@import "./src/styles/variables";

.btn {
  font-family: $main-family !important;
  padding: 6px 20px 6px 20px;
  font-size: 1rem;
  min-width: 64px;
  position: relative;
  box-sizing: border-box;
  transition: all 0.4s ease;
  font-weight: 400;
  line-height: 1.75;
  border-radius: 100px;
  outline: none;
  justify-content: center;
  height: 40px;
  // box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
  //     0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  // background-color: rgba(204, 204, 204, 0.4);

  & i {
    font-size: 1rem;
    margin-right: 8px;
  }

  span {
    display: inline-flex;
    align-items: center;
    font-size: 1rem !important;
  }

  &:focus {
    outline: none;
  }

  &-block {
    width: 100%;
    display: block;
    text-align: center;
  }

  &-default {
    background-color: #e0e0e0;
    &.btn-primary {
      @apply bg-primary;
      &:hover {
        &:before {
          content: "";
          background: rgba(0, 0, 0, 0.2);
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
        }
      }

      & span {
        color: #ffffff;
        position: relative;
        z-index: 1;
      }
    }
    &.btn-secondary {
      @apply bg-secondary;
      &:hover {
        &:before {
          content: "";
          background: rgba(0, 0, 0, 0.2);
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
        }
      }

      & span {
        color: #ffffff;
        position: relative;
        z-index: 1;
      }
    }

    &.btn-xs {
      padding: 0px 6px 5px 6px !important;
      height: auto;
      & span {
        font-size: 0.8rem !important;
      }
    }

    &.btn-xxs {
      padding: 0px 2px 1px 2px !important;
      height: auto;
      min-width: 1em !important;
      & span {
        font-size: 0.8rem !important;
      }
      & i {
        margin-left: 2px !important;
        margin-right: 2px !important;
      }
    }
  }

  &-outlined {
    &.btn-primary {
      @apply border-2 border-primary;
      &:hover {
        &:before {
          content: "";
          background: rgba(0, 0, 0, 0.1);
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
        }
      }

      & span {
        color: $primary-color;
        position: relative;
        z-index: 1;
      }
    }
    &.btn-secondary {
      @apply border-2 border-secondary;

      & span {
        color: $secondary-color;
        position: relative;
        z-index: 1;
      }

      &:hover {
        &:before {
          content: "";
          background: rgba($color: $secondary-color, $alpha: 1);
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
        }

        span {
          color: #ffffff;
        }
      }
    }
  }

  &-none {
    &.btn-primary {
      border: none;
      &:hover {
        &:before {
          content: "";
          background: rgba(0, 0, 0, 0.1);
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
        }
      }
      & span {
        color: $primary-color;
        position: relative;
        z-index: 1;
      }
    }
    &.btn-secondary {
      border: none;

      & span {
        color: $secondary-color;
        position: relative;
        z-index: 1;
      }

      &:hover {
        &:before {
          content: "";
          background: rgba($color: $secondary-color, $alpha: 1);
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
        }

        span {
          color: #ffffff;
        }
      }
    }
  }

  &-view-coverage {
    font-size: 0.875rem;
    color: gray;
    line-height: 1.45;
  }

  &:disabled {
    background-color: #cccccc;
    color: #cccccc;
    cursor: not-allowed;
    & span {
      color: #aaaaaa;
      position: relative;
      z-index: 1;
    }

    &:hover {
      &:before {
        content: "";
        background: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }
  }
}

.btn-radius {
  font-family: $main-family !important;
  padding: 6px 20px 6px 20px;
  font-size: 1rem;
  min-width: 64px;
  position: relative;
  box-sizing: border-box;
  transition: all 0.4s ease;
  font-weight: 400;
  line-height: 1.75;
  border-radius: 20px;
  outline: none;
  justify-content: center;
  align-items: center;
  height: 40px;
  overflow: hidden;
  // background-color: rgba(204, 204, 204, 0.4);

  & i {
    font-size: 1rem;
    margin-right: 8px;
  }

  span {
    display: inline-flex;
    align-items: center;
    font-size: 1rem !important;
  }

  &:focus {
    outline: none;
  }

  &-block {
    width: 100%;
    display: block;
    text-align: center;
  }

  &-default {
    background-color: #e0e0e0;
    &.btn-primary {
      @apply bg-primary shadow-button;
      &:hover {
        &:before {
          content: "";
          // background: rgba(0, 0, 0, 0.2);
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
        }
      }

      & span {
        color: #ffffff;
        position: relative;
        z-index: 1;
      }
    }
    &.btn-secondary {
      @apply bg-secondary;
      &:hover {
        &:before {
          content: "";
          // background: rgba(0, 0, 0, 0.2);
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
        }
      }

      & span {
        color: #ffffff;
        position: relative;
        z-index: 1;
      }
    }

    &.btn-xs {
      padding: 3px 10px 3px 10px !important;
      font-size: 11px !important;
      height: auto;
      & span {
        font-size: 11px !important;
      }
    }

    &.btn-xxs {
      padding: 0px 2px 1px 2px !important;
      height: auto;
      min-width: 1em !important;
      margin-left: 2px;
      margin-right: 2px;
      & span {
        font-size: 0.6rem !important;
      }
      & i {
        margin-left: 2px !important;
        margin-right: 2px !important;
      }
    }
  }

  &-outlined {
    &.btn-primary {
      box-shadow: 0px 1px 6px $primary-color;
      &:hover {
        &:before {
          content: "";
          // background: rgba(0, 0, 0, 0.1);
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
        }
      }

      & span {
        color: $primary-color;
        position: relative;
        z-index: 1;
      }
    }
    &.btn-secondary {
      border: 2px solid $secondary-color;

      & span {
        color: $secondary-color;
        position: relative;
        z-index: 1;
      }

      &:hover {
        &:before {
          content: "";
          background: rgba($color: $secondary-color, $alpha: 1);
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
        }

        span {
          color: #ffffff;
        }
      }
    }
  }

  &-none {
    &.btn-primary {
      border: none;
      &:hover {
        &:before {
          content: "";
          background: rgba(0, 0, 0, 0.1);
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
        }
      }
      & span {
        color: $primary-color;
        position: relative;
        z-index: 1;
      }
    }
    &.btn-secondary {
      border: none;

      & span {
        color: $secondary-color;
        position: relative;
        z-index: 1;
      }

      &:hover {
        &:before {
          content: "";
          background: rgba($color: $secondary-color, $alpha: 1);
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
        }

        span {
          color: #ffffff;
        }
      }
    }
  }

  &-view-coverage {
    font-size: 0.875rem;
    color: gray;
    line-height: 1.45;
  }

  &:disabled {
    background-color: #cccccc;
    color: #cccccc;
    cursor: not-allowed;
    & span {
      color: #aaaaaa;
      position: relative;
      z-index: 1;
    }

    &:hover {
      &:before {
        content: "";
        background: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }
  }
}
