$app_name:hero;
@import "./src/styles/variables";

@mixin layout-grid {
  min-width: 1100px;
  display: grid;
  grid-template-columns: 180px 120px 180px 70px 80px 100px 100px 90px 150px 100px;
  & > div {
    padding: 10px 5px;
  }
}

.table-grid {
  overflow-x: scroll;
  &-head {
    @include layout-grid();
    font-size: 10px;
    color: $font-secondary;
  }

  &-body {
    @include layout-grid();
    background-color: #ffffff;
    font-size: 12px;
    margin-bottom: 5px;
    color: $font-primary;

    &-full {
      width: 100%;
      padding: 10px 5px;
      text-align: center;
      background-color: #ffffff;
    }

    img {
      width: 40px;
      box-shadow: $box-shadow;
      border-radius: $radius;
    }

    .num-value {
      display: inline-block;
      width: 90px;
      text-align: left;
    }

    .cmi-label {
      background-color: rgba($color: #646e78, $alpha: 0.1);
      border-radius: 8px;
      display: inline-block;
      padding: 1px 2px;
      font-size: 10px;
      width: 30px;
      text-align: center;
    }

    &.actions {
      vertical-align: middle;
    }
  }
}
// .table-list {
//   margin-top: 0.5rem;
//   table {
//     width: 100%;

//     thead {
//       tr {
//         th {
//           font-size: 11px;
//           color: $label-color;
//           padding: 8px 16px;
//           text-align: left;
//         }
//       }
//     }

//     tbody {
//       tr {
//         td {
//           background-color: #ffffff;
//           border-top: solid 5px #f4f6f8;
//           border-bottom: solid 5px #f4f6f8;
//           padding: 8px 16px;
//           font-size: 12px;
//           color: $table-color;
//           vertical-align: top;
//           transition: all 0.2s ease;

//           &.actions {
//             vertical-align: middle;
//           }

//           i.block-icon {
//               width: 30px;
//               display: inline-block;
//           }

//           .num-value {
//               display: inline-block;
//               width: 90px;
//               text-align: left;
//           }

//           .cmi-label {
//               background-color: rgba($color: #646E78, $alpha: .1);
//               border-radius: 8px;
//               display: inline-block;
//               padding: 1px 2px;
//               font-size: 10px;
//               width: 30px;
//               text-align: center;
//           }
//         }

//         // &:hover {
//         //   td {
//         //     background-color: #e0edfa;
//         //   }
//         // }
//       }
//     }
//   }
// }
