$app_name:hero;
.datepicker-range {
  &--box {
    display: grid;
    grid-template-columns: 1fr 50px 1fr;
  }
  &--modal {
    width: 600px;
    position: absolute;
    z-index: 9;

    .react-calendar__viewContainer {
      display: flex;
      > div {
        margin: 0 15px;
      }
    }

    .react-calendar__tile--active {
      background-color: rgba(235, 0, 139, 0.4);
    }

    .react-calendar__tile--rangeStart {
      border-top-left-radius: 25%;
      border-bottom-left-radius: 25%;
      background-color: rgb(235, 0, 139);
    }

    .react-calendar__tile--rangeEnd {
      border-top-right-radius: 25%;
      border-bottom-right-radius: 25%;
      background-color: rgb(235, 0, 139);
    }

    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
      background-color: rgb(235, 0, 139);
    }
  }
}
