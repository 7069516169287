$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/mixin";

.staff-label {
    margin-bottom: 8px;
    &.inline {
        display: flex;
        justify-content: space-between;
    }
    &-key {
        color: $label-color;
        font-size: 12px;
    }
    &-value {
        font-size: 12px;
    }
}
