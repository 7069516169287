$app_name:hero;
.bubble {
  @apply relative pb-10 pl-20 mb-60;
  /* width: 245px; */
  border-bottom: 3px solid #ffffff;
  border-left: 3px solid #ffffff;
}

.bubble:before,
.bubble:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
}

.speech:before {
  left: 17px;
  bottom: -32px;
  border: 15px solid;
  border-color: #ffffff transparent transparent #ffffff;
}

.speech:after {
  left: 20px;
  bottom: -24px;
  border: 12px solid;
  border-color: theme("colors.primary") transparent transparent
    theme("colors.primary");
}
