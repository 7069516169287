$app_name:hero;
@import "./src/styles/variables";
.preloading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  cursor: wait;

  &-loading-content {
    width: 150px;
    padding: 16px 16px;
    font-size: 0.8rem;
    overflow: hidden;
    text-align: center;
    border-radius: 4px;
    margin-top: 16px;
    position: relative;
    background-color: #fff;
    // border: solid 2px $secondary-color;
    box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
      0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
      0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
      0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);

    &::after {
      content: "";
      height: 5px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(to right, $primary-color, #ffffff);
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation-name: loading;
      animation-name: loading;
      animation-iteration-count: infinite;
    }
  }
}

@keyframes loading {
  from {
    width: 0%;
  }

  to {
    width: 300%;
  }
}
