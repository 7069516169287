$app_name:hero;
@import "../../../styles/variables";

.custom-file {
  position: relative;

  input {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    padding: 0.375rem 0.75rem;
  }

  label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding: 0.375rem 0.75rem;
    border: 1px solid rgba($color: #cccccc, $alpha: 0.5);
    outline: none;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 1rem;
    line-height: 1.5;
    display: block;
    transition: all 0.1s ease-in;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        display: block;
        height: 100%;
        padding: .375rem 1.75rem;
        line-height: 1.5;
        color: #495057;
        content: "Browse";
        background-color: #e9ecef;
        border-left: inherit;
        border-radius: 0 .25rem .25rem 0;
    }

    &:disabled {
      background-color: rgba($color: #cccccc, $alpha: 0.1);
      cursor: not-allowed;
    }

    &:focus {
      border-color: $primary-color;
      outline: 0;
      box-shadow: 0 0 0 0.1rem rgba($color: $primary-color, $alpha: 0.3);
    }
  }
}

.form-upload {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border: 1px solid rgba($color: #cccccc, $alpha: 0.5);
  outline: none;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 1rem;
  line-height: 1.5;
  display: block;
  transition: all 0.1s ease-in;

  &:disabled {
    background-color: rgba($color: #cccccc, $alpha: 0.1);
    cursor: not-allowed;
  }

  &:focus {
    border-color: $primary-color;
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba($color: $primary-color, $alpha: 0.3);
  }
}

.upload-preview {
  background-color: #eeeeee;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
