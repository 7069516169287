$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/grid";

.view-order {
  color: $font-primary;
  padding: 16px;

  .order-detail-link {
    text-decoration: underline;
  }

  &-title {
    font-size: 11px;
    color: #848484;
    .title-border {
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 10px;
      color: #000000c1;
    }

    .title-bottom {
      font-size: 12px;
      font-weight: 200;
      color: #949494;
      padding-top: 5px;
    }
  }

  &-component {
    border-radius: 4px;
    width: 100%;
    font-size: 0.9rem;

    .primary-text {
      color: $primary-color;
    }

    .secondary-text {
      color: $secondary-color;
    }

    .img-insurer {
      display: inline-flex;
      width: 50px;
      height: 50px;
      background-color: rgba($color: #cccccc, $alpha: 0.2);
      border-radius: 4px;
      overflow: hidden;
    }

    .phone-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0.5rem;
      font-size: 0.8rem;

      i {
        &::before {
          font-size: 0.8rem;
        }
        font-size: 1.2rem;
        margin-right: 8px;
      }
    }

    .continue-btn {
      background-color: $primary-color;
      padding: 7px 3px;
      width: 100%;
      border-radius: 5px;
      color: #ffffff;
      font-size: 0.8rem;
      outline: none;
    }
  }

  .btn-pay-slip {
    display: flex;
    align-items: left;
    justify-content: left;
    text-align: left;
    width: 100%;

    i {
      font-size: 0.9rem;
      margin-right: 4px;
    }
  }

  .table-grid {
    min-width: 100%;
    // min-width: 1400px;

    .grid-th {
      display: grid;
      grid-template-columns: 10rem 2fr 4rem 40px 6rem 8rem 6rem 6rem 6rem 5rem 12rem;
      // grid-template-columns: 150px 130px 60px 40px 1fr 100px 1fr 70px 100px 1fr 100px;
      font-size: 10px;
      color: $font-secondary;
      & > div {
        padding: 10px 5px;
      }
    }

    .grid-tbody {
      display: grid;
      grid-template-columns: 10rem 2fr 4rem 40px 6rem 8rem 6rem 6rem 6rem 5rem 12rem;
      // grid-template-columns: 150px 130px 60px 40px 1fr 100px 1fr 70px 100px 1fr 100px;
      font-size: 12px;
      background-color: #ffffff;
      margin-bottom: 5px;
      color: $font-primary;
      & > div {
        padding: 10px 5px;

        .badges {
          margin-top: 5px;
        }
        .expired_at {
          font-size: 10px;
        }

        img {
          border-radius: 4px;
        }
      }

      &-full {
        width: 100%;
        padding: 10px 5px;
        text-align: center;
        background-color: #ffffff;
      }
    }

    .grid-expand-table {
      width: 100%;
      background-color: #ffffff;
      margin-bottom: 5px;
      margin-top: -4px;
      padding: 10px 5px;

      &-grid-th {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 100px;
        font-size: 12px;
        color: $font-secondary;
        & > div {
          padding: 10px;
        }
      }

      &-grid-tbody {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 100px;
        background-color: #f1f4f6;
        margin-bottom: 5px;
        font-size: 12px;
        & > div {
          padding: 10px;

          img {
            border-radius: 4px;
          }

          button i {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}

.view-payments-component {
  .grid {
    font-size: 12px;
  }
}
