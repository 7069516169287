$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/mixin";
.from-group {
  margin-bottom: 1rem;

  > label {
    @include label-style;

    span {
      color: $error-color;
    }
  }

  .label-block {
    display: inline-block;
  }

  .label-inline {
    display: inline;
  }
}
