$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/mixin";
.input-text {
  position: relative;

  .form-input {
    @include input-style;

    &.text-currency {
      text-align: right;
      padding-right: 1.25rem;
    }

    &:read-only {
      background-color: rgba($color: #cccccc, $alpha: 0.1);
      cursor: not-allowed;
    }

    &:focus {
      border-color: $primary-color;
      outline: 0;
      box-shadow: 0 0 0 0.1rem rgba($color: $primary-color, $alpha: 0.3);
    }

    &.has-error {
      border-color: $error-color;
      &:focus {
        border-color: $error-color;
        outline: 0;
        box-shadow: 0 0 0 0.1rem rgba($color: $error-color, $alpha: 0.3);
      }
    }

    &-error {
      color: $error-color;
      font-size: 0.8rem;
      display: flex;
      align-items: center;

      & i {
        font-size: 1rem;
        margin-right: 5px;
      }
    }
  }

  .currency {
    position: absolute;
    font-size: 12px;
    color: #1E2124;
    right: 8px;
    top: 7px;
  }
}
