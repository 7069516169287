$app_name:hero;
@import "./src/styles/variables";

.payments-alert-modal {
  .alert-details {
    ul.message-lines {
      list-style-type: none;
    }
  }

  .error-icon.material-icons {
    color: red;
  }
}
