$app_name:hero;
@import "../../../src/styles/variables";
@import "./checkmark";

.radio-component {
  position: relative;
  &-button {
    display: block;
    width: 100%;
    height: 30px;
    position: relative;
    text-align: left;
    padding-left: 25px;
    font-size: 1rem;
    outline: none;
    align-items: center;
    .checkmark {
      @include checkmark-styles;
    }
    &:focus {
      @include button-focus;
    }
    &.checked {
      @include button-checked;
    }
  }
}
