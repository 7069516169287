$app_name:hero;
.staff-search-form {
    width: 300px;
    position: relative;
    .search-input {
        width: 100%;
        border: solid 1px #DEE0E3;
        border-radius: 15px;
        outline: none;
        padding: 5px 15px;
        font-size: 12px;
    }
    .search-button {
        position: absolute;
        top: 3px;
        right: 5px;
        color: #BCC2C7;
        outline: none;
    }
}