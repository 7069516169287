$app_name:hero;
@import "./src/styles/variables";
.handle-error {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  height: 100vh;
  padding-top: 100px;
  background-color: #ffffff;

  .header-text {
    color: $font-primary;
    font-size: 18px;
  }

  .support-text {
    color: #121119;
    font-size: 14px;
  }

  .contact-text {
    color: #747474;
    font-size: 12px;
    font-weight: 300;
  }

  .back-button {
    margin: 30px;
  }

  .error-img {
    padding: 1.5rem;
    margin: auto;
    background-color: #ffffff;
    border-radius: 2px;
    width: 100%;
    overflow-x: scroll;
    font-size: 0.9rem;

    img {
      max-width: 100%;
      margin: 0 auto;
    }
  }
}
