$app_name:hero;
@import "./src/styles/variables";

.summary-box {
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 0px 3px 6px #8a949e34;
  border-radius: 4px;

  &.primary {
    background-color: $primary-color;
    color: #ffffff;
    box-shadow: 0px 1px 6px #c11a7d60;
  }

  &-label {
    text-align: left;
    font-size: 12px;
  }

  &-value {
    text-align: right;
    margin-top: 1rem;
    font-size: 28px;
    font-weight: 700;
  }
}
