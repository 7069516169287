$app_name:hero;
.nav-link {
  span.label {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 3px;
      @apply bg-secondary;
      opacity: 0;
      transition: all 0.4s;
    }
  }

  &:hover {
    span.label {
      &::before {
        opacity: 1;
      }
    }
  }
}
.button-animated {
  animation-duration: 1000ms;
  animation-name: animateButton;
}

@keyframes animateButton {
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
