$app_name:hero;
@import "./src/styles/variables";
.from-group {
  margin-bottom: 1rem;

  > label {
    font-size: 0.875rem;
    font-weight: 400;
    margin-bottom: 0.25rem;
    color: rgba($color: #000000, $alpha: 1);

    span {
      color: $error-color;
    }
  }
  &-test {
    width: 50%;
  }

  .label-block {
    display: inline-block;
  }

  .label-inline {
    display: inline;
  }

  .label-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
