$app_name:hero;
@import '../../../styles/variables';

.form-select {
    width: 100%;
    padding: .375rem .75rem;
    border: 1px solid rgba($color: #cccccc, $alpha: .5);
    outline: none;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 1rem;
    line-height: 1.5;
    display: block;
    transition: all .1s ease-in;
    height: calc(2.25rem + 2px);
    background-color: #ffffff;

    &:disabled {
        background-color: rgba($color: #cccccc, $alpha: .1);
        cursor: not-allowed;
    }

    &:focus {
        border-color: $primary-color;
        outline: 0;
        box-shadow: 0 0 0 0.1rem rgba($color: $primary-color, $alpha: .3);
    }

    // &:hover {
    //     border: 1px solid $primary-color;
    // }
}