$app_name:hero;
@import "./src/styles/variables";
.link-btn {
  padding: 10px 20px 6px 20px;
  font-size: 1rem;
  min-width: 64px;
  position: relative;
  box-sizing: border-box;
  transition: all 0.4s ease;
  font-weight: 700;
  line-height: 1.75;
  border-radius: 4px;
  outline: none;
  justify-content: center;
  display: inline-block;
  // box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
  //     0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  // background-color: rgba(204, 204, 204, 0.4);

  & i {
    font-size: 1.2rem;
    margin-right: 8px;
  }

  span {
    display: inline-flex;
    align-items: center;
  }

  &:focus {
    outline: none;
  }

  &-block {
    width: 100%;
    display: block;
    text-align: center;
  }

  &-primary {
    background-color: $primary-color;
    &:hover {
      &:before {
        content: "";
        background: rgba(0, 0, 0, 0.2);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }

    & span {
      color: #ffffff;
      position: relative;
      z-index: 1;
    }
  }

  &-secondary {
    background-color: $secondary-color;
    &:hover {
      &:before {
        content: "";
        background: rgba(0, 0, 0, 0.2);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }

    & span {
      color: #ffffff;
      position: relative;
      z-index: 1;
    }
  }

  &-grey {
    background-color: $grey-color;
    &:hover {
      &:before {
        content: "";
        background: rgba(0, 0, 0, 0.2);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }

    & span {
      color: #ffffff;
      position: relative;
      z-index: 1;
    }
  }
}
