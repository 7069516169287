$app_name:hero;
@import "./src/styles/variables";
.renew-list {
  padding: 16px;

  .result-box {
    text-align: right;
    margin-top: 5px;
    color: #8a949e;
    font-size: 11px;
    margin-bottom: 1.5rem;
  }
}
