$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/grid";

:root {
  --payment-method-width: 100%;
}

.payment-wrapper {
  padding: 16px;
  & > .motor-discount-conponent {
    width: calc(100% - 2rem);
  }
}

.order-number {
  color: #848484;
}

.payment-methods {
  background-color: $swatch1-1;
  padding: 20px;
  border-radius: 12px;
  margin-top: 1rem;
  box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
    0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
    0 0.25rem 0.53125rem rgba(2, 3, 17, 0.05),
    0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
  width: var(--payment-method-width);

  .material-icons {
    color: #c0c0c0;
    text-align: center;
  }

  &-wrap {
    background-color: #faf9f9ef;
    // margin: -19px;
    padding: 20px;
  }

  &-tab-head {
    display: grid;
    grid-template-columns: auto auto auto auto 1fr;
    grid-column-gap: 16px;
    margin-bottom: 1rem;
    margin-top: 15px;
  }

  &-tab {
    border-bottom: 4px solid transparent;
    padding-bottom: 0.5rem;
    padding: 0 8px;
    text-align: center;

    &.tab-on {
      border-bottom: 4px solid $secondary-color;
    }
  }
  &-info {
    grid-template-columns: 1fr auto;
    grid-column-gap: 1.5rem;
  }
  .payment-total {
    @apply flex flex-nowrap justify-between items-baseline pb-4 w-full;
  }
  .total-amount {
    @apply text-secondary;
    font-size: 1.125rem;
    font-weight: 500;
  }
}

.btn-back {
  border: 1px solid #dee0e3;
  border-radius: 5px;
  padding: 2px 10px;
  color: $primary-color;
  margin-top: 4px;
  display: inline-block;
}

.thai-qr-frame {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: auto;

  &-header {
    width: 100%;
    height: 26px;
    background-color: #1e4066;
    display: inline-block;
    text-align: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    &-logo {
      width: 50px;
      display: inline-block;
    }
  }

  &-body {
    background-color: #ffffff;
    height: auto;
    text-align: center;
    padding: 15px;
    box-shadow: 0px 3px 6px #dddddd;
    border-radius: 4px;

    &-qr-image {
      overflow: hidden;
      height: auto;
      margin-bottom: 5px;

      img {
        max-width: 100%;
      }
    }

    &-text {
      &-header {
        font-size: 16px;
        margin-bottom: 5px;

        &-qr {
          font-size: 12px;
        }
      }

      &-body {
        font-size: 10px;
      }

      &-footer {
        font-size: 7px;
        font-weight: 100;
      }
    }
  }
}

.col-center {
  text-align: center;

  .text-grey-small {
    font-size: 11px;
    color: #8a949e;
  }

  .bullet {
    background-color: #fefae7;
    background-color: #fefae7;
    display: inline-block;
    padding: 2px 8px;
    border-radius: 25%;
    padding-left: 20px;
    position: relative;

    &::before {
      position: absolute;
      content: "";
      width: 8px;
      height: 8px;
      background-color: #f4d843;
      margin-right: 16px;
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
    }
  }
}

.list-bank {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  .bank-logo {
    width: 27px;
    height: 27px;
    margin: 1px;

    &-visa {
      width: 75px;
    }

    &-master-card {
      width: 49px;
    }

    &-pci {
      width: 52px;
    }

    &-safe {
      width: 180px;
    }
  }
}

.banktransfer-grid {
  display: grid;
  grid-template-columns: 70px 1fr;
}
