$app_name:hero;
@import "./src/styles/variables";

.dashboard__container {
  @apply flex;
  @apply overflow-hidden;

  height: calc(100vh - #{$header-height});
  max-height: calc(100vh - #{$header-height});
}

.dashboard__table {
  @apply table-fixed;
  @apply w-full;
  @apply border-separate;

  border-spacing: 0;
}

.bg-gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.36878501400560226) 100%
  );
}
