$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/grid";

.text-primary {
  color: $primary-color;
}

.text-secondary {
  color: $secondary-color;
}

.plan-header {
  padding: 0.75rem;
  background: $secondary-color;
  margin-top: 1rem;
  text-align: center;
  font-size: 1.125rem;
  color: white;
}

.motor {
  &-component {
    padding: 0.5rem;
    line-height: 1rem;

    button {
      margin-top: 0.5rem;
      background: #faf9f9ef;
      color: #5f5e5e;
      border: #e6e6e6 solid 1px;
      border-radius: 15px;
      padding: 0.33rem;
      // font-size: 1rem;
      outline: none;

      i {
        font-size: 1.1rem;
      }
    }

    .image {
      background: #e6e4e4ef;
      width: 70px;
      font-size: 0.8rem;
    }

    &-card {
      background-color: $swatch1-1;
      padding: 0.5rem 1rem;
      border-radius: 10px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
        0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
        0 0.25rem 0.53125rem rgba(2, 3, 17, 0.05),
        0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);

      .text-sm {
        font-size: 10px;
        color: #848484;
      }

      .text-detail {
        color: #646e78;
      }
    }
  }
}
