$app_name:hero;
@import "./src/styles/variables";

.preview-pay-slip {
  display: inline-flex;
  align-items: center;
  color: $primary-color;
  outline: none;
  i {
    font-size: 0.8rem;
    margin-right: 4px;
  }
}
