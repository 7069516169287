$app_name:hero;
.stepper {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    &-list {
        position: relative;
        padding: 0px 30px;
        color: #C4C4C4;
        font-size: 1.1rem;

        &.active {
            color: #000000;
        }

        &::before {
            content: "";
            position: absolute;
            border: solid 1px #C4C4C4;
            width: 25px;
            top: 50%;
            left: -15px;
        }

        &:first-child {
            &::before {
                width: 0;
            } 
        }
    }
}