$app_name:hero;
@import "./src/styles/variables";

.badges {
  display: inline-block;
  font-size: 10px;
  padding: 2px 8px;
  border-radius: 4px;
  text-align: center;
  min-width: 100px;
  //   box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
  //     0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
  //     0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
  //     0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);

  &.info {
    color: $error-color;
    font-size: 0.2rem;
  }

  &.waiting_inspection {
    background-color: $status-waiting-color;
    color: #ffffff;
  }

  &.waiting_policy {
    background-color: $status-waiting-color;
    color: #ffffff;
  }

  &.failed_inspection {
    background-color: $status-failed-color;
    color: #ffffff;
  }

  &.success {
    background-color: $status-success-color;
    color: #ffffff;
  }

  &.cancel {
    background-color: $status-cancel-color;
  }

  &.open {
    border: solid 1px rgba($color: #cccccc, $alpha: 1);
    color: $font-primary;
  }

  &.waiting_payment {
    background-color: $status-waiting-color;
    color: #ffffff;
  }

  &.completed {
    background-color: $status-success-color;
    color: #ffffff;
  }

  &.failed {
    background-color: $status-pending-color;
  }

  &.waiting_verify {
    background-color: $status-waiting-color;
    color: #ffffff;
  }

  &.issuing {
    background-color: $status-pending-color;
  }

  &.closed {
    background-color: $status-cancel-color;
    color: #ffffff;
  }

  &.agent-info {
    color: $error-color;
    font-size: 0.2rem;
  }

  &.agent-waiting_inspection {
    background-color: $status-waiting-color;
    color: #ffffff;
  }

  &.agent-waiting_policy {
    background-color: $status-waiting-color;
    color: #ffffff;
  }

  &.agent-failed_inspection {
    background-color: $status-failed-color;
    color: #ffffff;
  }

  &.agent-success {
    background-color: $status-success-color;
    color: #ffffff;
  }

  &.agent-cancel {
    background-color: $status-cancel-color;
  }

  &.agent-open {
    border: solid 1px rgba($color: #cccccc, $alpha: 1);
    color: $font-primary;
  }

  &.agent-waiting_payment {
    background-color: $status-waiting-color;
    color: #ffffff;
  }

  &.agent-completed {
    background-color: $status-success-color;
    color: #ffffff;
  }

  &.agent-failed {
    background-color: $status-pending-color;
  }

  &.agent-waiting_verify {
    background-color: $status-waiting-color;
    color: #ffffff;
  }

  &.agent-issuing {
    background-color: $status-pending-color;
  }

  &.agent-closed {
    background-color: $status-cancel-color;
    color: #ffffff;
  }

  &.quotation-open {
    background-color: #fefae7;
    padding: 2px 8px;
    border-radius: 25px;
    padding-left: 20px;
    position: relative;
    margin-right: 0px;

    &::before {
      position: absolute;
      content: "";
      width: 8px;
      height: 8px;
      background-color: #f4d843;
      margin-right: 16px;
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
    }
  }

  &.order {
    &-open {
      margin-right: 0px;
      background-color: #ffffff;
      padding: 2px 8px;
      border-radius: 25px;
      padding-left: 20px;
      position: relative;
      border: solid 1px rgba($color: #f3f3f3, $alpha: 1);

      &::before {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        background-color: #bdbdbd;
        margin-right: 16px;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
      }
    }

    &-waiting_payment {
      margin-right: 0px;
      background-color: #fefae7;
      padding: 2px 8px;
      border-radius: 25px;
      padding-left: 20px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        background-color: #f3d735;
        margin-right: 16px;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
      }
    }

    &-completed {
      margin-right: 0px;
      background-color: #eafcf2;
      padding: 2px 8px;
      border-radius: 25px;
      padding-left: 20px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        background-color: #4adf91;
        margin-right: 16px;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
      }
    }

    &-failed {
      margin-right: 0px;
      background-color: #fde8ea;
      padding: 2px 8px;
      border-radius: 25px;
      padding-left: 20px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        background-color: #eb3f4d;
        margin-right: 16px;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
      }
    }

    &-waiting_verify {
      margin-right: 0px;
      background-color: #fff6e5;
      padding: 2px 8px;
      border-radius: 25px;
      padding-left: 20px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        background-color: #ffb429;
        margin-right: 16px;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
      }
    }

    &-closed {
      margin-right: 0px;
      background-color: #f2f2f2;
      padding: 2px 8px;
      border-radius: 25px;
      padding-left: 20px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        background-color: #949494;
        margin-right: 16px;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
      }
    }
  }

  &.payment {
    &-status {
      padding: 2px 8px;
      border-radius: 25px;
      padding-left: 20px;
      position: relative;

      &-waiting_payment {
        background-color: #fefae7;
      }

      &-pending {
        background-color: #fefae7;
      }

      &-waiting_verify {
        background-color: #fff6e5;
      }

      &-paid {
        background-color: #eafcf2;
      }

      &-failed {
        background-color: #fde8ea;
      }
    }

    &-method {
      background-color: #f0f0f0;
      padding: 2px 8px;
      border-radius: 25px;
      padding-left: 20px;
      position: relative;
    }
  }

  &.policy {
    &-waiting_inspection {
      margin-right: 0px;
      background-color: #fff6e5;
      padding: 2px 8px;
      border-radius: 25px;
      padding-left: 20px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        background-color: #ffb429;
        margin-right: 16px;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
      }
    }

    &-success {
      margin-right: 0px;
      background-color: $policy-success-bg;
      padding: 2px 8px;
      border-radius: 25px;
      padding-left: 20px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        background-color: #2db8e2;
        margin-right: 16px;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
      }
    }

    &-failed_inspection {
      margin-right: 0px;
      background-color: #fde8ea;
      padding: 2px 8px;
      border-radius: 25px;
      padding-left: 20px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        background-color: #eb3f4d;
        margin-right: 16px;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
      }
    }

    &-waiting_policy {
      margin-right: 0px;
      background-color: #fff6e5;
      padding: 2px 8px;
      border-radius: 25px;
      padding-left: 20px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        background-color: #ffb429;
        margin-right: 16px;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
      }
    }

    &-waiting_approval {
      margin-right: 0px;
      background-color: #fff6e5;
      padding: 2px 8px;
      border-radius: 25px;
      padding-left: 20px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        background-color: #ffb429;
        margin-right: 16px;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
      }
    }

    &-cancel {
      margin-right: 0px;
      background-color: #f2f2f2;
      padding: 2px 8px;
      border-radius: 25px;
      padding-left: 20px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        background-color: #949494;
        margin-right: 16px;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
      }
    }

    &-failed {
      margin-right: 0px;
      background-color: #fefae7;
      padding: 2px 8px;
      border-radius: 25px;
      padding-left: 20px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        background-color: #f3d735;
        margin-right: 16px;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
      }
    }

    &-issuing {
      margin-right: 0px;
      background-color: #fefae7;
      padding: 2px 8px;
      border-radius: 25px;
      padding-left: 20px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        background-color: #f3d735;
        margin-right: 16px;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
      }
    }
  }

  &.renew-cmi {
    background-color: #fefae7;
    padding: 2px 8px;
    border-radius: 25px;
    padding-left: 20px;
    position: relative;
    margin-right: 0px;
  }
}

.download-pay-slip {
  display: inline-flex;
  align-items: center;
  font-size: 0.7rem;

  .slip-icon {
    margin-right: 7px;
    margin-top: 5px;
    font-size: 0.9rem;
    color: $secondary-color;
  }
}