$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/grid";
@import "./src/styles/modal";

.showPayslipModal {
  .modal-box {
    max-height: 80vh;

    img {
      width: 20rem;
      margin: auto;
      padding: 0.5rem;
    }
  }
}
