$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/grid";

.quotations-list {
  &-table {
    width: 100%;

    tr {
      td {
        padding: 16px;
        background-color: $grey-color;
      }
    }
  }
  &-expend {
    .bg {
      // background-color: #f5f5f5;
      background-color: #ffffff;
    }
  }

  &-box {
    padding: 8px 16px;
    background-color: #f3f2f2;
    // margin-bottom: 1rem;

    .title-info {
      display: grid;
      grid-template-columns: 6% 14% 15% 5% 13% 10% 13% 9% 1fr;
      padding: 0.5rem 0rem;
      justify-items: center;
      align-items: center;
      color: $font-secondary;
      border-bottom: 1px solid #cbcbcb;
      margin-bottom: 1rem;
      font-size: 0.9rem;
    }

    .products-list {
      display: grid;
      grid-template-columns: 6% 14% 15% 5% 13% 10% 13% 9% 1fr;
      justify-items: center;
      align-items: center;
      font-size: 0.9rem;
      color: $font-primary;
      font-family: $main-family2;
    }

    .bin {
      font-size: 1.5rem;
      color: $grey-color;
      outline: none;
    }

    .icon-shop {
      position: relative;
      color: #ffffff;
      margin-right: 0.3rem;
      top: 2px;
      font-size: 1rem;
    }

    .close-deal-and-buy-btn {
      padding: 8px 25px;
      background: $primary-color;
      border-radius: 5px;
      color: #ffffff;
      font-size: 0.9rem;
      outline: none;
    }

    .img-insurer {
      width: 55px;
      background-color: #ececec;
      margin-bottom: 0.5rem;
    }
  }
}
