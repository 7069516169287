$app_name:hero;
.section-tool {
  .bg-tool {
    background-position: center right;
    width: 155px;
    height: 155px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 155px 155px;
    top: 50%;
    transform: translateY(-50%);
  }

  .bg-system {
    background-image: url("../../../../../assets/homepage/system-icon-large.svg");
  }

  .bg-armory {
    background-image: url("../../../../../assets/homepage/armory-icon-large.svg");
  }

  .bg-support {
    background-image: url("../../../../../assets/homepage/support-icon-large.svg");
  }

  .bg-mobile-tools {
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 40% 40%;

    &.left {
      background-position: center left;
    }

    &.right {
      background-position: center right;
    }

    &.bg-system {
      background-image: url("../../../../../assets/homepage/system-icon-large.svg");
    }

    &.bg-armory {
      background-image: url("../../../../../assets/homepage/armory-icon-large.svg");
    }

    &.bg-support {
      background-image: url("../../../../../assets/homepage/support-icon-large.svg");
    }
  }

  .bg-left {
    left: 60px;
  }
  .bg-right {
    right: 60px;
  }

  .detail-image {
    padding: 0 130px;
  }
}

.slick-slide {
  padding: 30px 0;
}

.slick-current {
  img {
    transition: all 0.4s ease;
    transform: scale(1.7);
  }
}

.d3phone {
  background-image: url("../../../../../assets/homepage/3d-phone.svg");
  position: absolute;
  width: 312px;
  height: 350px;
  top: -250px;
  right: 0;
  z-index: 1;
  overflow-y: hidden;

  @media (min-width: 200px) {
    display: none;
  }

  @media (min-width: 1440px) {
    // right: 100px;
    transform: translateX(0px);
  }

  @media (min-width: 1600px) {
    transform: translateX(-110px);
    display: block;
  }
}
