$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/grid";

.ph-item {
  &,
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  overflow: hidden;
  margin-top: 16px;
  background-color: $ph-bg;
  //border: 1px solid darken($ph-bg, 10%);
  border-radius: $ph-border-radius;
  box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
    0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
    0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
    0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    animation: phAnimation $ph-animation-duration linear infinite;
    background: linear-gradient(
        to right,
        rgba($ph-bg, 0) 46%,
        rgba($ph-bg, 0.35) 50%,
        rgba($ph-bg, 0) 54%
      )
      50% 50%;
  }

  // > * {
  //   flex: 1 1 auto;
  //   display: flex;
  //   flex-flow: column;
  //   padding-right: ($ph-gutter / 2);
  //   padding-left: ($ph-gutter / 2);
  // }
}

.ph-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ($ph-spacer / 2);

  div {
    height: 10px;
    margin-bottom: ($ph-spacer / 2);
    background-color: $ph-color;
  }
  .big,
  &.big div {
    height: 20px;
    margin-bottom: $ph-spacer;
  }
  .empty {
    background-color: rgba($ph-bg, 0);
  }
}

// .ph-col-2 {
//   flex: 0 0 percentage(2 / 12);
// }
// .ph-col-4 {
//   flex: 0 0 percentage(4 / 12);
// }
// .ph-col-6 {
//   flex: 0 0 percentage(6 / 12);
// }
// .ph-col-8 {
//   flex: 0 0 percentage(8 / 12);
// }
// .ph-col-10 {
//   flex: 0 0 percentage(10 / 12);
// }
// .ph-col-12 {
//   flex: 0 0 percentage(12 / 12);
// }

.ph-card-header {
  height: 50px;
  width: 100%;
  background-color: $ph-color;
  margin-bottom: $ph-spacer;
}

.ph-card-price {
  height: 55px;
  width: 100%;
  background-color: $ph-color;
  margin-bottom: $ph-spacer;
}

.ph-card-content {
  width: 100%;
  padding-top: $ph-spacer;
  padding-bottom: $ph-spacer;

  div {
    width: 100%;
    height: 18px;
    margin-bottom: ($ph-spacer);
    background-color: $ph-color;
  }
}

.ph-card-content-s {
  width: 100%;
  padding: 0.5rem 1rem;

  div {
    width: 100%;
    height: 3px;
    background-color: $ph-color;
  }
}

.ph-card-button {
  width: 100%;
  height: 48.5px;
  margin-bottom: ($ph-spacer);
  background-color: $ph-color;

  &.no-space {
    margin-bottom: 0;
  }
}

.ph-avatar {
  position: relative;
  width: 100%;
  min-width: 60px;
  background-color: $ph-color;
  margin-bottom: $ph-spacer;
  border-radius: $ph-avatar-border-radius;
  overflow: hidden;

  &::before {
    content: " ";
    display: block;
    padding-top: 100%;
  }
}

.ph-picture {
  width: 100%;
  height: 120px;
  background-color: $ph-color;
  margin-bottom: $ph-spacer;
}

@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
}
