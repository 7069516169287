$app_name:hero;
@import "./src/styles/variables";

@mixin table-grid {
  // min-width: 1300px;
  min-width: 1200px;
  display: grid;
  grid-template-columns: 90px 180px 70px 50px 100px 100px 90px 130px 100px 140px 180px;
  // grid-template-columns: 150px 90px 180px 70px 50px 100px 100px 90px 130px 100px 100px 140px;

  & > div {
    padding: 10px 5px;
  }
}

.renew-grid {
  overflow-x: scroll;

  &-head {
    @include table-grid();
    font-size: 10px;
    color: $font-secondary;
  }

  &-body {
    @include table-grid();
    background-color: #ffffff;
    font-size: 12px;
    margin-bottom: 5px;
    color: $font-primary;

    &-full {
      width: 100%;
      padding: 10px 5px;
      text-align: center;
      background-color: #ffffff;
    }

    .selling-price {
      font-size: 14px;
      font-weight: 500;
    }

    .num-value {
      display: inline-block;
      padding-left: 7px;
      font-size: 14px;
      font-weight: 500;
    }

    .cmi-label {
      background-color: rgba($color: #646e78, $alpha: 0.1);
      border-radius: 8px;
      display: inline-block;
      padding: 1px 2px;
      font-size: 10px;
      width: 30px;
      text-align: center;
    }

    img {
      width: 40px;
      box-shadow: $box-shadow;
      border-radius: $radius;
    }

    .file {
      display: flex;
      align-items: center;
    }
  }
}
