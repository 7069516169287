$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/grid";

.multiple-quotation {
  .doc {
    position: flex;
    font-size: 1.7rem;
    color: $grey-color;
    margin-right: 0.5rem;
    margin-left: 1rem;
  }

  &-component {
    padding: 32px;
    margin: 0rem 2rem;
    background-color: #ffffff;
    border: 1px solid rgba($color: #b1b1b1, $alpha: 0.5);
    border-radius: 10px;
  }

  &-body {
    background-color: #ffffff;
    padding: 16px;

    .row > .custom-col {
      padding: 0;
    }

    .img {
      width: 60px;
      background-color: rgba($color: #cccccc, $alpha: 0.2);
      box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
        0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
        0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
        0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
      img {
        border-radius: 4px;
      }
      margin-right: 10px;
    }

    h2 {
      padding: 0.5rem 1rem;
      font-size: 1rem;
      font-weight: 700;
      background-color: $secondary-color;
      color: #ffffff;
      margin-top: 16px;
      margin-left: -0.5rem;
      margin-right: -0.5rem;

      &:first-child {
        margin-top: 0;
      }
    }

    ul.multiple-products-cover {
      & > li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 0.5rem 1rem;
        color: $font-primary;
        font-size: 0.9rem;
        height: 55px;
        border-bottom: solid 1px rgba($color: #eeeeee, $alpha: 1);
        &.active {
          color: $primary-color;
        }

        &.coverages-value {
          justify-content: center;
          color: #000000;
        }
      }
    }

    ul.multiple-products-info {
      // li:first-child {
      //   height: 65px;
      // }
      & > li {
        display: flex;
        flex-wrap: wrap;
        color: $font-primary;
        margin-left: 0.5rem;
        margin-bottom: 1rem;
        font-size: 0.9rem;

        &.active {
          color: $primary-color;
        }

        small {
          color: #848484;
        }

        .img {
          margin-bottom: 0.5rem;
          margin-right: 16px;
          width: 50px;

          img {
            max-width: 100%;
          }
        }

        &.coverages-value {
          justify-content: center;
          color: #000000;
        }
      }
    }
  }
}
