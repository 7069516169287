$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/grid";

@mixin card-style {
  box-shadow: $box-shadow;
  border-radius: $radius;
  padding: $padding-space;
  margin-bottom: 16px;
  background-color: #ffffff;
}

.renew-form {
  padding: $padding-space;

  &-top {
    @include card-style;
    padding: 16px 16px 8px;

    .grid {
      display: flex;
      justify-content: space-between;

      .staff-label {
        padding-right: 16px;

        &:last-child {
          padding-right: 0;
        }

        .cmi-label {
          background-color: rgba($color: #646e78, $alpha: 0.1);
          border-radius: 8px;
          display: inline-block;
          padding: 1px 2px;
          font-size: 10px;
          width: 30px;
          text-align: center;
          margin-left: 8px;
        }

        .cmi-label-success {
          background-color: #e9fbf2;
          border-radius: 8px;
          display: inline-flex;
          padding: 1px 5px;
          font-size: 10px;
          width: auto;
          text-align: center;
          margin-left: 8px;
          position: relative;
          align-items: center;
          color: #167946;

          span {
            font-size: .6rem;
            color: #ffffff;
            border-radius: 50%;
            padding: 1px;
            background-color: #2DD881;
            margin-right: 4px;
          }
        }
      }
    }
  }

  &-left {
    @include card-style;
  }

  &-right {
    @include card-style;
  }
}
