$app_name:hero;
@import "./src/styles/variables";

.icon-status {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin-top: 5px;

  span {
    font-size: 1rem;
  }

  &.true {
    background-color: $status-success-color;
  }

  &.false {
    background-color: $status-failed-color;
  }
}
