$app_name:hero;
@import "./src/styles/variables";
.switch-toggle {
  width: 40px;
  border-radius: 20px;
  border: solid 1px #c4c4c4;
  height: 26px;
  padding: 2px;
  background-color: rgba($color: #333333, $alpha: 0.2);
  position: relative;
  cursor: pointer;

  .bullet {
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    //transition: all 1s ease-in-out;
  }

  &.small {
    width: 30px;
    border-radius: 20px;
    border: solid 1px #c4c4c4;
    height: 20px;
    padding: 2px;

    .bullet {
      width: 14px;
      height: 14px;
      background-color: #fff;
      border-radius: 50%;
      position: absolute;
      top: 2px;
      //transition: all 1s ease-in-out;
    }
  }

  &.off {
    .bullet {
      left: 2px;
    }
  }
  &.on {
    background-color: $secondary-color;
    .bullet {
      right: 2px;
    }
  }
}
