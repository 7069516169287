$app_name:hero;
@import "./variables";
/* purgecss start ignore */
/* only apply Purgecss to Tailwindcss's utility class */
@import "tailwindcss/base";
@import "tailwindcss/components";
/* purgecss end ignore */
@import "tailwindcss/utilities";

@font-face {
  font-family: "icomoon";
  src: url("/src/assets/fonts/icomoon.eot?oilod2");
  src: url("/src/assets/fonts/icomoon.eot?oilod2#iefix") format("embedded-opentype"),
    url("/src/assets/fonts/icomoon.ttf?oilod2") format("truetype"),
    url("/src/assets/fonts/icomoon.woff?oilod2") format("woff"),
    url("/src/assets/fonts/icomoon.svg?oilod2#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "icomoon";
  src: url("/src/assets/fonts/hero-download/icomoon.eot?semg9r");
  src: url("/src/assets/fonts/hero-download/icomoon.eot?semg9r#iefix") format("embedded-opentype"),
    url("/src/assets/fonts/hero-download/icomoon.ttf?semg9r") format("truetype"),
    url("/src/assets/fonts/hero-download/icomoon.woff?semg9r") format("woff"),
    url("/src/assets/fonts/hero-download/icomoon.svg?semg9r#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "gettmorefont";
  src: url("/src/assets/fonts/gettmorefont.eot?sekws5");
  src: url("/src/assets/fonts/gettmorefont.eot?sekws5#iefix") format("embedded-opentype"),
    url("/src/assets/fonts/gettmorefont.ttf?sekws5") format("truetype"),
    url("/src/assets/fonts/gettmorefont.woff?sekws5") format("woff"),
    url("/src/assets/fonts/gettmorefont.svg?sekws5#gettmorefont") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="gettmore-icon-"],
[class*=" gettmore-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "gettmorefont" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "heroiconfont";
  src: url("/src/assets/fonts/heroicon.eot?sekws5");
  src: url("/src/assets/fonts/heroicon.eot?sekws5#iefix") format("embedded-opentype"),
    url("/src/assets/fonts/heroicon.ttf?sekws5") format("truetype"),
    url("/src/assets/fonts/heroicon.woff?sekws5") format("woff"),
    url("/src/assets/fonts/heroicon.svg?sekws5#gettmorefont") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "icomoon";
  src: url("/src/assets/fonts/font-3/icomoon.eot?t0cso9");
  src: url("/src/assets/fonts/font-3/icomoon.eot?t0cso9#iefix") format("embedded-opentype"),
    url("/src/assets/fonts/font-3/icomoon.ttf?t0cso9") format("truetype"),
    url("/src/assets/fonts/font-3/icomoon.woff?t0cso9") format("woff"),
    url("/src/assets/fonts/font-3/icomoon.svg?t0cso9#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="hero-icon-"],
[class*=" hero-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "heroiconfont" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-moz-selection {
  /* Code for Firefox */
  @apply bg-secondary text-white;
}

::selection {
  @apply bg-secondary text-white;
}

// .icon-person:before {
//   content: "\e908";
// }
// .icon-protect:before {
//   content: "\e909";
// }
// .icon-star:before {
//   content: "\e90a";
// }
// .icon-chat:before {
//   content: "\e905";
// }
// .icon-document:before {
//   content: "\e906";
// }
// .icon-shop:before {
//   content: "\e90b";
// }
// .icon-group:before {
//   content: "\e900";
// }
// .icon-house:before {
//   content: "\e901";
// }
// .icon-motor:before {
//   content: "\e902";
// }
// .icon-motorbike:before {
//   content: "\e903";
// }
// .icon-travel:before {
//   content: "\e904";
// }
// .icon-hero-download:before {
//   content: "\e900";
//   color: #474747;
// }
// .icon-hero-link:before {
//   content: "\e901";
//   color: #474747;
// }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-business:before {
  content: "\e919";
}
.icon-cancer:before {
  content: "\e91a";
}
.icon-compulsory:before {
  content: "\e91b";
}
.icon-diamond:before {
  content: "\e91c";
}
.icon-engineer:before {
  content: "\e91d";
}
.icon-fire:before {
  content: "\e91e";
}
.icon-golf:before {
  content: "\e91f";
}
.icon-group:before {
  content: "\e920";
}
.icon-health:before {
  content: "\e921";
}
.icon-IAR:before {
  content: "\e922";
}
.icon-insurance:before {
  content: "\e923";
}
.icon-lightning:before {
  content: "\e924";
}
.icon-marine:before {
  content: "\e925";
}
.icon-misc:before {
  content: "\e926";
}
.icon-motorbike:before {
  content: "\e927";
}
.icon-PA:before {
  content: "\e928";
}
.icon-Setting:before {
  content: "\e929";
}
.icon-SME:before {
  content: "\e92a";
}
.icon-wrench:before {
  content: "\e92b";
}
.icon-book:before {
  content: "\e90f";
}
.icon-buy:before {
  content: "\e910";
}
.icon-document:before {
  content: "\e911";
}
.icon-email:before {
  content: "\e912";
}
.icon-endorsement:before {
  content: "\e913";
}
.icon-image:before {
  content: "\e914";
}
.icon-pin:before {
  content: "\e915";
}
.icon-customer-phone:before {
  content: "\e916";
}
.icon-phone:before {
  content: "\e917";
}
.icon-vdo:before {
  content: "\e918";
}
.icon-travel:before {
  content: "\e90d";
}
.icon-custom-motor:before {
  content: "\e90e";
}
.icon-order:before {
  content: "\e908";
}
.icon-Compulsory:before {
  content: "\e900";
}
.icon-endrosement:before {
  content: "\e901";
}
.icon-faq:before {
  content: "\e902";
}
.icon-gettmore-more:before {
  content: "\e903";
}
.icon-House:before {
  content: "\e904";
}
.icon-info:before {
  content: "\e905";
}
.icon-menu:before {
  content: "\e906";
}
.icon-Motor:before {
  content: "\e907";
}
.icon-motor:before {
  content: "\e907";
}
.icon-policylist:before {
  content: "\e909";
}
.icon-policytracking:before {
  content: "\e90a";
}
.icon-quotation:before {
  content: "\e90b";
}
.icon-renew:before {
  content: "\e90c";
}

.gettmore-icon-travel:before {
  content: "\e90d";
}
.gettmore-icon-custom-motor:before {
  content: "\e90e";
}
.gettmore-icon-order:before {
  content: "\e908";
}
.gettmore-icon-Compulsory:before {
  content: "\e900";
}
.gettmore-icon-endrosement:before {
  content: "\e901";
}
.gettmore-icon-faq:before {
  content: "\e902";
}
.gettmore-icon-gettmore-more:before {
  content: "\e903";
}
.gettmore-icon-House:before {
  content: "\e904";
}
.gettmore-icon-info:before {
  content: "\e905";
}
.gettmore-icon-menu:before {
  content: "\e906";
}
.gettmore-icon-Motor:before {
  content: "\e907";
}
.gettmore-icon-policylist:before {
  content: "\e909";
}
.gettmore-icon-policytracking:before {
  content: "\e90a";
}
.gettmore-icon-quotation:before {
  content: "\e90b";
}
.gettmore-icon-renew:before {
  content: "\e90c";
}

.hero-icon-note:before {
  content: "\E90F";
}
.hero-icon-cart:before {
  content: "\E910";
}
.hero-icon-clipboard:before {
  content: "\E911";
}
.hero-icon-mail:before {
  content: "\E912";
}
.hero-icon-message:before {
  content: "\E913";
}
.hero-icon-picture:before {
  content: "\E914";
}
.hero-icon-location:before {
  content: "\E915";
}
.hero-icon-phone:before {
  content: "\E916";
}
.hero-icon-arrowphone:before {
  content: "\E917";
}
.hero-icon-play:before {
  content: "\E918";
}

[class^="icon-3-"],
[class*=" icon-3-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-3-business:before {
  content: "\e919";
}
.icon-3-cancer:before {
  content: "\e91a";
}
.icon-3-compulsory:before {
  content: "\e91b";
}
.icon-3-diamond:before {
  content: "\e91c";
}
.icon-3-engineer:before {
  content: "\e91d";
}
.icon-3-fire:before {
  content: "\e91e";
}
.icon-3-golf:before {
  content: "\e91f";
}
.icon-3-group:before {
  content: "\e920";
}
.icon-3-health:before {
  content: "\e921";
}
.icon-3-IAR:before {
  content: "\e922";
}
.icon-3-insurance:before {
  content: "\e923";
}
.icon-3-lightning:before {
  content: "\e924";
}
.icon-3-marine:before {
  content: "\e925";
}
.icon-3-misc:before {
  content: "\e926";
}
.icon-3-motorbike:before {
  content: "\e927";
}
.icon-3-PA:before {
  content: "\e928";
}
.icon-3-Setting:before {
  content: "\e929";
}
.icon-3-SME:before {
  content: "\e92a";
}
.icon-3-wrench:before {
  content: "\e92b";
}
.icon-3-book:before {
  content: "\e90f";
}
.icon-3-buy:before {
  content: "\e910";
}
.icon-3-document:before {
  content: "\e911";
}
.icon-3-email:before {
  content: "\e912";
}
.icon-3-endorsement:before {
  content: "\e913";
}
.icon-3-image:before {
  content: "\e914";
}
.icon-3-pin:before {
  content: "\e915";
}
.icon-3-customer-phone:before {
  content: "\e916";
}
.icon-3-phone:before {
  content: "\e917";
}
.icon-3-vdo:before {
  content: "\e918";
}
.icon-3-travel:before {
  content: "\e90d";
}
.icon-3-custom-motor:before {
  content: "\e90e";
}
.icon-3-order:before {
  content: "\e908";
}
.icon-3-Compulsory:before {
  content: "\e900";
}
.icon-3-endrosement:before {
  content: "\e901";
}
.icon-3-faq:before {
  content: "\e902";
}
.icon-3-gettmore-more:before {
  content: "\e903";
}
.icon-3-House:before {
  content: "\e904";
}
.icon-3-info:before {
  content: "\e905";
}
.icon-3-menu:before {
  content: "\e906";
}
.icon-3-Motor:before {
  content: "\e907";
}
.icon-3-policylist:before {
  content: "\e909";
}
.icon-3-policytracking:before {
  content: "\e90a";
}
.icon-3-quotation:before {
  content: "\e90b";
}
.icon-3-renew:before {
  content: "\e90c";
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  // color: blue;
  font-family: $main-family !important;
  font-size: $body-font-2;
}
h1 {
  font-size: $h1;
}
h2 {
  font-size: $h2;
}
h3 {
  font-size: $h3;
}
h4 {
  font-size: $h4;
}

h5 {
  font-size: $h5;
}

h6 {
  font-size: $h6;
}

.icon-size {
  font-size: 1rem;
}

body {
  background-color: $bg-main;
}

.filter-overlay {
  position: absolute;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.1);
  top: 0;
  left: 0;
  z-index: 99;
}

.page-wrapper {
  margin-top: 63px;
}

.content-wrapper {
  margin-left: 60px;
}

hr {
  border-top: solid 1px #e9ecef;
  margin-bottom: 16px;
}

.sticky-menu {
  position: sticky;
  top: 85px;
}

.primary-text {
  color: $primary-color !important;
}

.secondary-text {
  color: $secondary-color !important;
}

.font-secondary-color {
  color: $secondary-color !important;
}

.font-primary-color {
  color: $primary-color !important;
}

.font-primary {
  color: $font-primary;
}

.font-secondary {
  color: $font-secondary;
}

.white-color {
  color: #ffffff;
}

.help-txt {
  color: rgba($color: #747474, $alpha: 0.4);
}

.error-txt {
  color: rgba($color: $error-color, $alpha: 1);
  font-size: 0.8rem;
}

.space-left-1 {
  margin-left: 30px;
}

.staff {
  &-wrapper {
    padding: 16px;

    h1 {
      margin-bottom: 16px;
    }

    &-content {
      padding: 16px 0;
      background-color: #ffffff;
    }
  }

  &-card {
    padding: 8px 16px 16px;
    background-color: #ffffff;
    border-radius: 4px;

    &-header {
      color: $secondary-color;
      padding-bottom: 8px;
      border-bottom: solid 1px #e9ecef;
      margin-bottom: 8px;
    }
  }

  &-actions {
    padding: 16px;
    padding-bottom: 0;
    background-color: #ffffff;

    &-right {
      display: flex;
      justify-content: flex-end;
    }
  }

  &-from-actions {
    display: flex;
    justify-content: flex-end;
  }
}

.link-tel {
  color: #121212;
}

.animated {
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

  &.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }

  &.slideInRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
  }
  &.slideOutRight {
    -webkit-animation-name: slideOutRight;
    animation-name: slideOutRight;
  }

  &.slideOutLeft {
    -webkit-animation-name: slideOutLeft;
    animation-name: slideOutLeft;
  }

  &.zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
  }

  &.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }

  &.bounceInLeft {
    animation-name: bounceInLeft;
  }

  &.bounceInRight {
    animation-name: bounceInRight;
  }

  &.fadeInUpBig {
    -webkit-animation-name: fadeInUpBig;
    animation-name: fadeInUpBig;
  }

  &.fadeInDownBig {
    -webkit-animation-name: fadeInDownBig;
    animation-name: fadeInDownBig;
  }

  &.slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
  }

  &.delay-05s {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  &.delay-01s {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -moz-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    -o-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeInUpBig {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0%, 0);
    transform: translate3d(0, 0%, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -150%, 0);
    transform: translate3d(0, -150%, 0);
  }
}

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -150%, 0);
    transform: translate3d(0, -150%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(120%, 0, 0);
  }
}

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0) scaleX(3);
  }

  60% {
    transform: translate3d(25px, 0, 0) scaleX(1);
  }

  75% {
    transform: translate3d(-10px, 0, 0) scaleX(0.98);
  }

  90% {
    opacity: 1;
    transform: translate3d(5px, 0, 0) scaleX(0.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0) scaleX(3);
  }

  60% {
    transform: translate3d(-25px, 0, 0) scaleX(1);
  }

  75% {
    transform: translate3d(10px, 0, 0) scaleX(0.98);
  }

  90% {
    opacity: 1;
    transform: translate3d(-5px, 0, 0) scaleX(0.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
    visibility: visible;
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.gm-paginate {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 8px 16px;
  margin-top: 1rem;

  // span {
  //   font-size: 11px;
  //   color: #9a9ea1;
  //   display: block;
  //   margin-bottom: 0.5rem;
  // }

  li {
    margin: 0 4px;

    a {
      min-width: 35px;
      height: 35px;
      box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
        0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03), 0 0.25rem 0.53125rem rgba(2, 3, 17, 0.05),
        0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
      padding: 5px 10px;
      text-align: center;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-left: none;
      font-size: 14px;
      background-color: #ffffff;
      color: #9a9ea1;
      border-radius: 4px;

      &:focus {
        outline: none;
      }
    }

    &.active {
      a {
        @apply bg-secondary;
        color: #ffffff;
      }
    }

    &.disabled {
      a {
        background-color: #ebebe4;
        color: #c6c6c6;
        cursor: not-allowed;
      }
    }
  }
}

.filter-area {
  &-box {
    display: grid;
    grid-template-columns: 350px 200px 280px 1fr;

    & > div {
      padding: 0 8px;
      margin-bottom: 16px;

      &:first-child {
        padding-left: 0;
      }
    }
  }
}

.sidebar-scroll {
  height: calc(100vh - 63px);
}

.section {
  @apply px-20 py-48 xl:px-60 2xl:px-120;
}

.section-y {
  @apply py-48;
}

.section-y-1\/2 {
  @apply py-24;
}

.section-x {
  @apply px-20 xl:px-60 2xl:px-120;
}

.section-x-1\/2 {
  @apply px-10 xl:px-30 2xl:px-60;
}

.main-section {
  @apply flex items-center justify-center w-full;
}

.section-header {
  &-breadcrumb {
    // Cancel the rule in src/pages/home/policy.scss
    &:before {
      content: none;
      margin-right: 0;
    }
  }
}

#slider-banner {
  .slick-slider {
    padding-bottom: 30px;

    img {
      display: inline-block;
    }
  }
  .slick-dots {
    position: absolute;
    display: flex !important;
    justify-content: center;
    margin-top: 20px;
    left: 50%;
    transform: translateX(-50%);

    li {
      &.slick-active {
        button {
          background-color: rgba($primary-color, 1);
          box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        }
      }

      button {
        text-indent: -9000px;
        width: 16px;
        height: 16px;
        background-color: rgba(#ccc, 1);
        margin: 5px 2px;
        border-radius: 50%;
      }
    }
  }
}

.detail-wrapper {
  hr {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: 0.25;
  }

  hr:not([size]) {
    height: 1px;
  }

  h6,
  h5,
  h4,
  h3,
  h2,
  h1 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }

  h1 {
    font-size: calc(1.375rem + 1.5vw);
  }
  @media (min-width: 1200px) {
    h1 {
      font-size: 2.5rem;
    }
  }

  h2 {
    font-size: calc(1.325rem + 0.9vw);
  }
  @media (min-width: 1200px) {
    h2 {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: calc(1.3rem + 0.6vw);
  }
  @media (min-width: 1200px) {
    h3 {
      font-size: 1.75rem;
    }
  }

  h4 {
    font-size: calc(1.275rem + 0.3vw);
  }
  @media (min-width: 1200px) {
    h4 {
      font-size: 1.5rem;
    }
  }

  h5 {
    font-size: 1.25rem;
  }

  h6 {
    font-size: 1rem;
  }

  p {
    margin-top: 0;
  }

  abbr[title],
  abbr[data-bs-original-title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
  }

  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }

  ol,
  ul {
    padding-left: 2rem;
  }

  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }

  dt {
    font-weight: 700;
  }

  dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
  }

  blockquote {
    margin: 0 0 1rem;
  }

  b,
  strong {
    font-weight: bolder;
  }

  small {
    font-size: 0.875em;
  }

  mark {
    padding: 0.2em;
    background-color: #fcf8e3;
  }

  sub,
  sup {
    position: relative;
    font-size: 0.75em;
    line-height: 0;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  a {
    color: #0d6efd;
    text-decoration: underline;
  }
  a:hover {
    color: #0a58ca;
  }

  a:not([href]):not([class]),
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
  }

  pre,
  code,
  kbd,
  samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
      monospace;
    font-size: 1em;
    direction: ltr /* rtl:ignore */;
    unicode-bidi: bidi-override;
  }

  pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: 0.875em;
  }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
  }

  code {
    font-size: 0.875em;
    color: #d63384;
    word-wrap: break-word;
  }
  a > code {
    color: inherit;
  }

  kbd {
    padding: 0.2rem 0.4rem;
    font-size: 0.875em;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem;
  }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700;
  }

  figure {
    margin: 0 0 1rem;
  }

  img,
  svg {
    vertical-align: middle;
  }

  table {
    caption-side: bottom;
    border-collapse: collapse;
  }

  caption {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #6c757d;
    text-align: left;
  }

  th {
    text-align: inherit;
    text-align: -webkit-match-parent;
  }

  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }
}

.ql-align-right {
  text-align: right;
}
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}
