$app_name:hero;
@import "./src/styles/variables";
.compare-bar {
  position: sticky;
  top: 60px;
  left: 0;
  z-index: 1202;
  width: 100%;
  height: 0;
  background-color: $compare-bar-bg;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  transition: all 0.2s ease-in;
  opacity: 0;
  z-index: -1;

  small {
    font-size: 0.7rem;
    margin-top: 0px;
    position: relative;
    display: block;
  }

  &.open {
    height: 120px;
    opacity: 1;
    z-index: 1;
  }

  &-box {
    flex-grow: 1;
    // margin-left: 280px;
    padding: 16px;

    &-list {
      background-color: #ffffff;
      border-radius: 4px;
      position: relative;
      box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
        0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
        0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
        0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
      // border: solid 2px $primary-color;
      padding: 8px;
      margin-top: 4px;
      display: flex;
      //   animation-duration: 5s;
      //   animation-name: slidein;
      min-height: 85px;

      &.empty {
        justify-content: center;
        align-items: center;
        height: 69px;
        text-align: center;
        background-color: rgba($color: #eeeeee, $alpha: 1);
        border: solid 2px #eeeeee;
        color: #cccccc;
      }

      &_close {
        position: absolute;
        top: -10px;
        right: -10px;
        width: 20px;
        height: 20px;
        background: red;
        border-radius: 50%;
        color: #fff;

        &:active,
        &:focus {
          outline: none;
        }

        i {
          font-size: 1rem;
          position: relative;
          top: 1px;
        }
      }

      .img {
        max-width: 40px;
        min-width: 40px;
        height: 40px;
        background-color: rgba($color: #000000, $alpha: 0.1);
        border-radius: 4px;
        margin-right: 16px;

        img {
          width: 60px;
          border-radius: 4px;
        }
      }
      .wrap {
        width: 100%;
        position: relative;
        .title {
          width: 100%;
          font-weight: 500;
        }

        .sub-title {
          font-size: 0.8rem;
          margin-top: -5px;
        }

        .price {
          text-align: right;
          font-size: 1.1rem;
          font-weight: 700;
          color: $secondary-color;
          position: absolute;
          right: 4px;
          bottom: 0px;
        }
      }
    }

    &-actions {
      margin-top: 4px;
      display: flex;
      flex-wrap: wrap;

      & > div {
        flex-basis: 100%;
        text-align: center;
        padding: 0 16px;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
