@import "./src/styles/variables";

@mixin input-style {
  border: 1px solid #c4c4c4cb;
  width: 100%;
  padding: 0.375rem 0.75rem;
  outline: none;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  line-height: 1.5;
  display: block;
  transition: all 0.1s ease-in;
  height: 32px;
  background-color: #ffffff;
}

@mixin label-style {
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 0.25rem;
  color: $header2-color;
}
