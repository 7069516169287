$app_name:hero;
@import "./src/styles/variables";

.table-list {
  margin-top: 0.5rem;
  overflow-x: scroll;
  overflow-y: hidden;

  table {
    width: 100%;
    min-width: 1300px;

    thead {
      tr {
        th {
          font-size: 11px;
          color: $label-color;
          padding: 8px 16px;
          font-weight: 300;
          text-align: left;
        }
      }
    }

    tbody {
      tr {
        td {
          background-color: #ffffff;
          border-top: solid 5px #f4f6f8;
          border-bottom: solid 5px #f4f6f8;
          padding: 8px 16px;
          font-size: 12px;
          color: $table-color;
          vertical-align: top;
          transition: all 0.2s ease;

          img {
            width: 40px;
            box-shadow: $box-shadow;
            border-radius: $radius;
            display: inline;
          }

          a.file {
            display: inline-flex;
            align-items: center;
            color: $primary-color;

            span {
              font-size: 0.9rem;
              margin-right: 4px;
            }
          }

          button {
            margin-bottom: 0.5rem;
            font-size: 11px;
            display: inline-block;
            margin: 0 4px;
          }

          &.actions {
            vertical-align: middle;
          }

          i.block-icon {
            width: 30px;
            display: inline-block;
          }

          .num-value {
            display: inline-block;
            width: 90px;
            text-align: left;
          }

          .cmi-label {
            background-color: rgba($color: #646e78, $alpha: 0.1);
            border-radius: 8px;
            display: inline-block;
            padding: 1px 2px;
            font-size: 10px;
            width: 30px;
            text-align: center;
          }
        }
      }
    }
  }
}

.lead-table {
  .table-grid {
    .grid-th {
      display: grid;
      grid-template-columns: 200px 90px 200px 130px 180px 120px 200px 120px 100px 1fr;
      font-size: 10px;
      color: $font-secondary;
      & > div {
        padding: 10px 5px;
      }
    }

    .grid-tbody {
      display: grid;
      grid-template-columns: 200px 90px 200px 130px 180px 120px 200px 120px 100px 1fr;
      font-size: 12px;
      background-color: #ffffff;
      margin-bottom: 5px;
      color: $font-primary;

      .selling-price {
        font-size: 14px;
        font-weight: 500;
      }

      &-full {
        width: 100%;
        padding: 10px 5px;
        text-align: center;
        background-color: #ffffff;
      }

      & > div {
        padding: 10px 5px;

        .badges {
          margin-top: 5px;
        }
        .expired_at {
          font-size: 10px;
        }

        .cmi-label {
          background-color: rgba($color: #646e78, $alpha: 0.1);
          border-radius: 8px;
          display: inline-block;
          padding: 1px 2px;
          font-size: 10px;
          width: 30px;
          text-align: center;
        }

        .num-value {
          display: inline-block;
          padding-left: 7px;
          font-size: 14px;
          font-weight: 500;
        }

        img {
          border-radius: 4px;
        }

        span,
        i {
          font-size: 0.9rem;
          margin-right: 5px;
        }

        button {
          margin-bottom: 5px;
          max-height: 30px;
        }
      }

      &-full {
        width: 100%;
        padding: 10px 5px;
        text-align: center;
        background-color: #ffffff;
      }
    }

    .grid-expand-table {
      width: 100%;
      background-color: #ffffff;
      margin-bottom: 5px;
      margin-top: -4px;
      padding: 10px 5px;

      &-grid-th {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 80px;
        font-size: 12px;
        color: $font-secondary;
        & > div {
          padding: 10px;
        }
      }

      &-grid-tbody {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 80px;
        background-color: #f1f4f6;
        margin-bottom: 5px;
        font-size: 12px;
        & > div {
          padding: 10px;

          img {
            border-radius: 4px;
          }

          button i {
            font-size: 0.8rem;
          }
        }
      }
    }
  }

  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    font-size: 10px;
    width: 200px;
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
}
