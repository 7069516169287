$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/grid";

.create-quotation {
  padding: 16px;
  .doc {
    font-size: 1.7rem;
    color: $grey-color;
    margin-right: 1rem;
  }

  &-header {
    margin-bottom: 1rem;
  }

  &-component {
    padding: 32px;
    background-color: #ffffff;
    box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
      0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
      0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
      0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
    border-radius: 4px;

    .font-primary {
      color: $font-primary;
    }

    .details {
      display: grid;
      grid-template-columns: 60% 1fr;

      h4 {
        margin-top: 0.5rem;
        margin-bottom: 0.25rem;
      }

      .item {
        margin-left: 1.5rem;
        font-size: 0.9rem;
      }
    }
  }

  .back-to-search {
    background-color: #ffffff;
    border-radius: 30px;
    padding: 5px 15px;
    border: solid 1px #8484847c;
    display: flex;
    span {
      transform: rotate(-180deg);
      margin-right: 5px;
    }
  }
}
