$app_name:hero;
.modal-upload-policy {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba($color: #000000, $alpha: 0.8);
  display: flex;
  justify-content: center;
  align-items: center;

  &-wrapper {
    width: 33.33%;
    padding: 16px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
      0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
      0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
      0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);

    h2 {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 32px;

      span {
        margin-right: 8px;
      }
    }

    &-grid-top {
      display: grid;
      grid-template-columns: 50% 50%;
      align-items: center;
      font-size: 0.8rem;
    }

    &-grid {
      display: grid;
      grid-template-columns: 90px 1fr;
      border-radius: 4px;
      border: solid 1px #eeeeee;
      background-color: #f9f9f9;
      margin-top: 8px;
      padding: 16px;
      font-size: 0.8rem;

      .img {
        margin-right: 16px;
        img {
          border-radius: 4px;
        }
      }
    }

    &-action {
      display: flex;
      justify-content: flex-end;

      button {
          margin-left: 8px;
      }
    }
  }
}
