$app_name:hero;
@import '../../../styles/variables';

.form-mesk {
    width: 100%;
    padding: .375rem .75rem;
    border: 1px solid rgba($color: #cccccc, $alpha: .5);
    outline: none;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 1rem;
    line-height: 1.5;
    display: block;
    transition: all .1s ease-in;

    &:read-only {
        background-color: rgba($color: #cccccc, $alpha: .1);
        cursor: not-allowed;
    }

    &:focus {
        border-color: $primary-color;
        outline: 0;
        box-shadow: 0 0 0 0.1rem rgba($color: $primary-color, $alpha: .3);
    }

    &.has-error {
        border-color: $error-color;
        &:focus {
            border-color: $error-color;
            outline: 0;
            box-shadow: 0 0 0 0.1rem rgba($color: $error-color, $alpha: .3);
        }
    }

    &-error {
        color: $error-color;
        font-size: 0.8rem;
        display: flex;
        align-items: center;

        & i {
            font-size: 1rem;
            margin-right: 5px;
        }
    }

    // &:hover {
    //     border: 1px solid $primary-color ;
    // }
}