$app_name:hero;
@import "./src/styles/variables";
.renew-list {
  padding: 16px;

  .result-box {
    text-align: right;
    margin-top: -8px;
    color: #8A949E;
    font-size: 11px;
    margin-bottom: 1.5rem;
  }
}
