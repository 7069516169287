$app_name:hero;
@import "./src/styles/variables";

.card-thankyou {
  max-width: 750px;

  &-title {
    @apply pt-4 pb-8 text-gray-800;
    h1 {
      color: #0a0a0a;
    }
    .check-sign {
      margin: 0 auto;
    }
  }
  &-focus {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    margin: 0 auto;
    padding: 1rem 0;

    .text-title {
      font-size: 16px;
      font-weight: 500;
    }

    .text-body {
      color: #848484;
      font-size: 12px;
    }
  }
  &-focus img {
    justify-self: end;
    border-radius: 10px;
  }
  &-amount {
    @apply font-bold text-2xl;
    color: $primary-color;
  }
  &-footer {
    border-top: 1px solid #f0f0f0;
    padding-top: 1rem;
    margin-top: 1rem;
  }

  .btn-follow-policy {
    @apply py-2 px-6 my-4;
    display: inline-block;
    color: white;
    background: $primary-color;
    border-radius: 8px;
  }

  .batch-verify {
    color: #985c00;
    background: #fff6e3;
    display: inline-block;
    padding: 0.15rem 1rem;
    margin-top: 0.75rem;
    border-radius: 14px;
    span {
      vertical-align: middle;
    }
    span:first-child {
      width: 10px;
      height: 10px;
      display: inline-block;
      background: #ffb100;
      border-radius: 50%;
      margin-right: 0.3rem;
    }
  }
}
