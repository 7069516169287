$app_name:hero;
@import "./src/styles/variables";

.contact-section {
  padding: 16px;
  .qrcode {
    margin: auto;
    width: 222px;
  }
  .text {
    font-size: 23px;
    line-height: 2rem;
    &-icon-box {
      width: 77px;
      height: 77px;
    }
    &-icon {
      color: $primary-color;
      //   margin: 0.5rem;
      font-size: 40px;
    }
    &-line {
      font-size: 1.25rem;
      margin-top: 1rem;
    }
    &-sub-detail {
      font-size: 1rem;
      padding-left: 45px;
    }
  }
}
