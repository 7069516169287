$app_name:hero;
@import "./src/styles/variables";
.hero-tabs {
  display: flex;
  border-bottom: solid 1px #dddddd;

  .tab {
    padding: 8px 16px;
    outline: none;
    border-bottom: solid 5px #ffffff;

    &.active {
      color: $primary-color;
      border-bottom: solid 5px $primary-color;
    }
  }
}

.hero-tab-panel {
  padding: 16px 0;
}
