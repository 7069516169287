$app_name:hero;
.main-search-product {
    background-position: top center;
    background-size: cover;
    padding-top: 80px;
    min-height: 100vh;

    &-title {
        font-size: 32px;
        font-weight: 500;
        color: white;
        text-align: center;
        padding-bottom: 16px;
    }

    &-box {
        padding: 2rem 12rem 0rem 12rem;
    }
}