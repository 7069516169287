$app_name:hero;
@import "./src/styles/variables";
.policy-page {
  padding: 16px;

  .table-grid {
    .grid-th {
      display: grid;
      grid-template-columns: 150px 150px 60px 40px 1fr 100px 80px 80px 1fr 80px;
      font-size: 10px;
      color: $font-secondary;
      & > div {
        padding: 10px 5px;
      }
    }

    .grid-tbody {
      display: grid;
      grid-template-columns: 150px 150px 60px 40px 1fr 100px 80px 80px 1fr 80px;
      font-size: 12px;
      background-color: #ffffff;
      margin-bottom: 5px;
      color: $font-primary;
      & > div {
        padding: 10px 5px;

        .badges {
          margin-top: 5px;
        }
        .expired_at {
          font-size: 10px;
        }

        img {
          border-radius: 4px;
        }
      }

      &-full {
        width: 100%;
        padding: 10px 5px;
        text-align: center;
        background-color: #ffffff;
      }
    }

    .grid-expand-table {
      width: 100%;
      background-color: #ffffff;
      margin-bottom: 5px;
      margin-top: -4px;
      padding: 10px 5px;

      &-grid-th {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 100px;
        font-size: 12px;
        color: $font-secondary;
        & > div {
          padding: 10px;
        }
      }

      &-grid-tbody {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 100px;
        background-color: #f1f4f6;
        margin-bottom: 5px;
        font-size: 12px;
        & > div {
          padding: 10px;

          img {
            border-radius: 4px;
          }

          button i {
            font-size: 0.8rem;
          }
        }
      }
    }
  }

  .table-grid2 {
    .grid-th {
      display: grid;
      grid-template-columns: 150px 150px 40px 100px 100px 80px 1fr 1fr 1fr 120px;
      font-size: 10px;
      color: $font-secondary;
      & > div {
        padding: 10px 5px;
      }
    }

    .grid-tbody {
      display: grid;
      grid-template-columns: 150px 150px 40px 100px 100px 80px 1fr 1fr 1fr 120px;
      font-size: 12px;
      background-color: #ffffff;
      margin-bottom: 5px;
      color: $font-primary;
      & > div {
        padding: 10px 5px;

        .badges {
          margin-top: 5px;
        }
        .expired_at {
          font-size: 10px;
        }

        img {
          border-radius: 4px;
        }

        .btn-pay-slip {
          justify-content: center;
        }

        .badges.policy-success {
          margin-right: 0;
        }
      }

      &-full {
        width: 100%;
        padding: 10px 5px;
        text-align: center;
        background-color: #ffffff;
      }
    }

    .grid-expand-table {
      width: 100%;
      background-color: #ffffff;
      margin-bottom: 5px;
      margin-top: -4px;
      padding: 10px 5px;

      &-grid-th {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 100px;
        font-size: 12px;
        color: $font-secondary;
        & > div {
          padding: 10px;
        }
      }

      &-grid-tbody {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 100px;
        background-color: #f1f4f6;
        margin-bottom: 5px;
        font-size: 12px;
        & > div {
          padding: 10px;

          img {
            border-radius: 4px;
          }

          button i {
            font-size: 0.8rem;
          }
        }
      }
    }
  }

  .btn-pay-slip {
    display: flex;
    align-items: center;

    i {
      font-size: 0.9rem;
      margin-right: 4px;
    }
  }

  &-title {
    font-size: 11px;
    color: #848484;
    .title-border {
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 10px;
      color: #000000c1;
    }

    .title-bottom {
      font-size: 12px;
      font-weight: 200;
      color: #949494;
      padding-top: 5px;
      padding-bottom: 20px;
    }
  }

  .remark {
    font-size: 10px;
    font-weight: 300;
    color: $primary-color;
    padding-bottom: 15px;
  }

  .phone {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.15rem;
    i {
      &::before {
        font-size: 0.8rem;
      }
      font-size: 1.2rem;
      margin-right: 8px;
    }
  }

  &-component {
    padding: 16px;
    margin-top: 1rem;
  }
}
