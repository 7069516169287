$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/grid";
.upload-payslip {
  padding: 32px;

  h1 {
    font-size: 1.3rem;
    display: flex;
    align-items: center;

    span {
      margin-right: 8px;
      color: $primary-color;
    }
  }

  &-content {
    padding: 16px;
    border-radius: 6px;
    margin: 16px 0;
    background-color: #f9f9f9;

    hr {
      margin-top: 16px;
    }

    .details {
      color: #959595;
      font-family: $main-family2;
      font-size: 0.8rem;
    }

    .insurer-detail {
      margin-top: 32px;
      display: grid;
      grid-template-columns: 80px 1fr;
      align-items: center;

      &-img {
        padding-right: 8px;

        img {
          max-width: 100%;
          border-radius: 4px;
        }
      }

      &-text {
        padding-left: 8px;

        h3 {
          font-size: 1.1rem;
        }

        p.small {
          font-size: 0.8rem;
          color: #959595;
        }

        p.price {
          font-size: 1.2rem;
          font-weight: 700;
          color: $secondary-color;
        }
      }
    }

    .upload-box {
      h4 {
        font-size: 1rem;
        font-weight: 700;
      }

      // p {
      //   color: #959595;
      //   font-family: $main-family2;
      //   font-size: 0.8rem;
      // }
    }
  }
}
