$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/grid";
.compare-page {
  padding: 16px;

  &-top-empty {
    position: relative;
    background-color: rgba($color: #eeeeee, $alpha: 1);
    border: solid 2px #4f4f4f;
    height: 100%;
    padding: 16px;
    border-radius: 4px;
    margin-top: 4px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
      0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
      0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
      0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
    font-size: 0.9rem;

    .compare-products-btn {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $font-secondary;
    }
  }

  .buy-now-btn {
    padding: 0.2rem 2rem;
    border-radius: 3px;
    color: #ffffff;
    background-color: #9883e5;
    font-size: 0.9rem;
  }

  &-packages-list {
    position: relative;
    background-color: #ffffff;
    padding: 10px;
    height: 100%;
    border-radius: 4px;
    margin-top: 4px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
      0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
      0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
      0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);

    &-box {
      .insure-details {
        display: grid;
        grid-template-columns: 68px 1fr;
      }

      // .price-details {
      //   display: grid;
      //   grid-template-columns: 1fr 1fr;
      // }

      .commission-price {
        font-size: 0.75rem;
        background-color: $secondary-color;
        border-radius: 5px;
        padding: 0px 3px;
        color: #ffffff;
      }

      .img {
        max-width: 50px;
        min-width: 50px;
        height: 50px;
        background-color: rgba($color: #000000, $alpha: 0.1);
        border-radius: 4px;
        img {
          width: 60px;
          border-radius: 4px;
        }
      }

      .secondary-color {
        color: $secondary-color;
      }
    }

    &_close {
      position: absolute;
      top: -10px;
      right: -10px;
      width: 20px;
      height: 20px;
      background: red;
      border-radius: 50%;
      color: #fff;

      &:active,
      &:focus {
        outline: none;
      }

      i {
        font-size: 1rem;
        position: relative;
        top: 1px;
      }
    }
  }

  &-top {
    margin-bottom: 16px;
    // position: sticky;
    // top: 65px;
    background-color: #f4f6f8;
    z-index: 2;
    padding: 16px 24px;
    .space-info {
      h1 {
        display: flex;
        align-items: center;
        color: $primary-color;
        i {
          margin-right: 8px;
        }
      }

      .text-2 {
        color: #747474;
      }

      .title {
        font-size: 1rem;
        font-weight: 500;
        margin-top: 8px;
        color: $font-primary;
        margin-bottom: 16px;
      }
    }

    .make-group-quotation-btn {
      margin-top: 10px;
      display: block;
      text-align: center;
      padding-bottom: 5px;
      height: auto;
    }

    .img-logo {
      width: 2.5rem;
      height: 2rem;
      background-color: #e6e6e6;
    }
  }

  &-body {
    background-color: #ffffff;
    // margin-top: 1.5rem;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
      0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
      0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
      0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);

    h2 {
      //   margin-top: 0.5rem;
      //   margin-bottom: 0.5rem;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      font-weight: 700;
      // background-color: #eeeeee;
      background-color: $secondary-color;
      color: #ffffff;
      margin-top: 16px;

      &:first-child {
        margin-top: 0;
      }
    }

    ul.compare-cover {
      & > li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 0.5rem 1rem;
        font-family: $main-family2;
        border-bottom: solid 1px rgba($color: #eeeeee, $alpha: 1);
        color: #4f4f4f;
        font-size: 0.8rem;
        font-weight: 400;
        &.active {
          color: $primary-color;
        }

        &.coverages-value {
          justify-content: center;
          font-weight: 400;
          font-size: 0.8rem;
          color: #000000;
        }

        // &:nth-child(odd) {
        //   background-color: rgba($color: #eeeeee, $alpha: 1);
        // }
        // height: 55px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
      }

      //   &.left {
      //     li {
      //       justify-content: left;
      //     }
      //   }
    }

    > .row [class*="col-"] {
      border-right: solid 1px rgba($color: #eeeeee, $alpha: 1);
      padding: 0;
      &:first-child {
        padding-left: 8px;
      }

      &:last-child {
        border-right: none;
        padding-right: 8px;
      }
    }
  }

  &-coverages-list {
    text-align: center;
    // border-right: solid 1px #00000029;
  }
}

.box-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.grid-3 {
  border-right: solid 1px rgba($color: #eeeeee, $alpha: 1);

  &:first-child {
    border-left: solid 1px rgba($color: #eeeeee, $alpha: 1);
  }

  // &.grid-bg {
  //   &:nth-child(odd) {
  //     background-color: rgba($color: #eeeeee, $alpha: 0.3);
  //   }
  // }
}
