$app_name:hero;
@import "./src/styles/variables";
.switcher {
  display: flex;
  label {
    padding: 5px 16px;
    border: solid 1px #C4C4C4;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease-in;
    min-width: 100px;
    text-align: center;
    font-size: 12px;
    &:first-child {
      border-right: none;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-left: none;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &.active {
      background-color: $secondary-color;
      color: #ffffff;

      &:first-child {
        border: solid 1px rgba($color: $secondary-color, $alpha: 0.4);
        border-right: none;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:last-child {
        border: solid 1px rgba($color: $secondary-color, $alpha: 0.4);
        border-left: none;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      left: 0;
      top: 0;
      cursor: pointer;
    }
  }
}
