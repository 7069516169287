$app_name:hero;
@import "./src/styles/variables";
.alert-msg {
  position: fixed;
  bottom: 16px;
  right: 16px;
  width: 300px;
  background-color: rgba($color: $error-color, $alpha: 0.9);
  color: #ffffff;
  z-index: 10001;
  padding: 16px 16px;
  border-radius: 4px;
  display: flex;
  -webkit-box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
    0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
    0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
    0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
  box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
    0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
    0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
    0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);

    & > i {
      margin-right: 8px;
    }
}
