$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/grid";
.card {
  background-color: #ffffff;
  padding: 16px;
  border-radius: 4px;
  margin-top: 16px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
    0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03), 0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
    0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
  transition: all 0.2s ease-in-out;

  &.zoom-in {
    transform: scale(1.02);
  }

  .card-header {
    display: flex;
    position: relative;
    .img {
      width: 60px;
      height: 60px;
      margin-right: 16px;
      overflow: hidden;

      img {
        border-radius: 4px;
      }
    }

    .insurer {
      margin-top: 5px;
      &-name {
        font-size: 0.9rem;
      }
      &-type {
        font-size: 0.8rem;
      }
    }

    .recommend-icon {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 0;
    }
  }

  .card-coverage {
    padding-top: 16px;
    padding-bottom: 16px;

    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        font-size: 0.8rem;
      }
      li.key {
        // Cancel the rule in src/pages/home/policy.scss
        &:before {
          content: none;
          margin-right: 0;
        }

        flex-basis: 60%;
        padding-right: 8px;
      }

      li.value {
        // Cancel the rule in src/pages/home/policy.scss
        &:before {
          content: none;
          margin-right: 0;
        }

        flex-basis: 40%;
        padding-left: 8px;
        text-align: right;
        font-weight: 500;
      }
    }
  }

  .card-actions {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: -16px;

    .more-detail {
      flex-basis: 50%;
      background-color: #f1f2f3;
      font-size: 0.8rem;
      padding: 6px 20px 6px 20px;
      min-width: 64px;
      position: relative;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      font-weight: 400;
      line-height: 1.75;
      height: 40px;
      border-bottom-left-radius: 4px;
      outline: none;
    }
    .buy-button {
      @apply bg-primary;
      flex-basis: 50%;
      color: #ffffff;
      font-size: 0.8rem;
      padding: 6px 10px 6px 10px;
      min-width: 64px;
      position: relative;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      font-weight: 400;
      line-height: 1.75;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom-right-radius: 4px;
      outline: none;

      i {
        font-size: 1.1rem;
        margin-right: 5px;
      }
    }
  }
}
