@import "../../../src/styles/variables";
@mixin checkmark-styles {
  position: absolute;
  top: 5px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 2px;
  transition: all 0.4s ease-in;

  &::after {
    content: "";
    position: absolute;
    left: 7px;
    top: -30px;
    width: 6px;
    height: 15px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg) translateY(-30px);
    -ms-transform: rotate(45deg) translateY(-30px);
    transform: rotate(45deg) translateY(-30px);
    overflow: hidden;
    opacity: 0;
    transition: all 0.4s ease-in;
  }
}

@mixin button-focus {
  outline: none;
}

@mixin button-checked {
  outline: none;
  .checkmark {
    @apply bg-primary block;
    &::after {
      display: block;
      opacity: 1;
      top: 0;
      transform: rotate(45deg) translateY(0px);
    }
  }
}
