$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/grid";

.generate-payment-link {
  .input-link {
    overflow: scroll;
    // width: 17rem;
    // height: 2.3rem;
    font-size: 0.7rem;
    font-weight: 50;
    background-color: #f8f7f7;
    border: 2px solid rgba($color: #cccccc, $alpha: 0.5);
    border-radius: 3px;
    padding: 0.3rem 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  // .button-copy-link,
  // .button-sms-link {
  //   background: $grey-color;
  //   border-radius: 3px;
  //   padding: 0.3rem 0.5rem;
  //   font-size: 0.8rem;
  //   color: $font-primary;
  //   width: 7rem;
  //   height: 2.3rem;
  //   margin-left: -1px;
  // }

  h3 {
    color: $font-primary;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 300;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
