$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/grid";

.coverageModal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9990;
  background-color: rgba($color: #000000, $alpha: 0.8);
  display: flex;
  align-items: center;
  justify-content: center;

  .item {
    color: $font-secondary;
    margin-left: 1.25rem;
    .item-left {
      color: $font-primary;
      text-align: left;
      font-size: 0.9rem;
      margin-bottom: 0.25rem;
    }
    .item-right {
      color: $font-primary;
      text-align: right;
      font-size: 0.9rem;
      font-weight: 600;
    }
  }

  &-overlay {
    position: fixed;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: wait;
  }

  &-box {
    position: relative;
    width: 80%;
    max-width: 700px;
    height: 90vh;
    background-color: #ffffff;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
      0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
      0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
      0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);

    &-close {
      position: absolute;
      right: 16px;
      top: 16px;

      &:active {
        outline: none;
      }

      .material-icons {
        color: #bdbdbd;
        font-size: 1rem;
      }
    }

    h4 {
      color: $font-primary;
      text-align: left;
      font-size: 1.1rem;
      font-weight: 700;
      margin-top: 1rem;
      margin-bottom: 0.25rem;
    }

    &-detailBox {
      width: 100%;
      height: calc(100% - 100px);
      background-color: #f1f0f0;
      padding: 0rem 1rem;
      border-radius: 10px;
      margin-top: 1rem;
      overflow: scroll;
      position: relative;
    }

    .image {
      background: #faf9f9ef;
      border-radius: 4px;
      width: 80px;
      height: 80px;
      font-size: 0.8rem;

      img {
        max-width: 100%;
        border-radius: 4px;
      }
    }

    .title {
      color: $font-primary;
      text-align: left;
      font-size: 1.1rem;
    }

    .subTitle {
      color: $font-primary;
      text-align: left;
      font-size: 0.7rem;
    }

    .motorInfo {
      color: $font-primary;
      text-align: left;
      font-size: 0.9rem;
    }

    .details {
      color: #c5c5c5;
      text-align: left;
      font-size: 0.9rem;
    }

    .header {
      // margin-top: 1.5rem;
    }
  }

  .d-grid {
    display: grid;
    grid-template-columns: 90px 1fr 150px;
    align-items: center;

    .img {
      max-width: 100%;
      margin-right: 16px;

      img {
        border-radius: 4px;
        box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
          0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
          0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
          0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
      }
    }

    .price {
      font-size: 1.3rem;
      font-weight: 700;
      margin-right: 5px;
      color: $secondary-color;
      text-align: right;
      margin-top: 1.5rem;
    }
  }
}

.coverageModal-cmi {
  &-header {
    &-grid {
      display: grid;
      grid-template-columns: 100px 1fr;

      div {
        margin-bottom: 0.5rem;
      }
    }
  }
  &-detail {
    h4 {
      background-color: #f1f2f3;
      padding: 4px 8px;
    }

    ul {
      margin-top: 8px;
      margin-bottom: 16px;
      padding-left: 20px;

      li {
        display: grid;
        padding: 8px 0;
        grid-template-columns: 1fr 50%;
        border-bottom: solid 1px #f5f5ff;
      }
    }
  }
}
