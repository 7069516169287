$app_name:hero;
@import "./src/styles/variables";

.orders-page {
  padding: 16px;

  &-title {
    font-size: 11px;
    color: #848484;

    .title-bottom {
      font-size: 12px;
      font-weight: 200;
      color: #949494;
      padding-top: 5px;
    }
  }

  .card {
    margin: 1.5rem;
    background: white;
    padding: 1.5rem;
    box-shadow: 1px 1px 6px -2px rgba(black, 0.5);
  }

  .continue-btn {
    background-color: $primary-color;
    padding: 0.3rem 0.5rem;
    color: #ffffff;
    border-radius: 3px;
    outline: none;
  }

  .result-box {
    text-align: right;
    margin-top: -20px;
    color: #b1b1b1;
    font-size: 10px;
    margin-bottom: 1.5rem;
  }
}
