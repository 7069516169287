$app_name:hero;
@import "./src/styles/variables";
.policy-page {
  padding: 16px;

  .order-detail-link {
    text-decoration: underline;
    color: #111111;
    font-weight: 500;
  }

  .result-box {
    text-align: right;
    margin-top: -40px;
    padding-bottom: 15px;
    color: #8a949e;
    font-size: 10px;
    margin-bottom: 1.5rem;
  }

  .result-box-all {
    text-align: right;
    margin-top: -10px;
    color: #8a949e;
    font-size: 10px;
    margin-bottom: 1.5rem;
  }

  .btn-pay-slip {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: right;
    width: 100%;

    i {
      font-size: 0.9rem;
      margin-right: 4px;
    }
  }

  @mixin grids {
    display: grid;
    grid-template-columns: 150px 80px 180px 180px 70px 40px 100px 100px 100px 80px 80px 80px 150px 30px 150px;
  }

  .cmi-label {
    background-color: rgba($color: #646e78, $alpha: 0.1);
    border-radius: 8px;
    display: inline-block;
    padding: 1px 2px;
    font-size: 10px;
    width: 30px;
    text-align: center;
  }

  .view-policy-component {
    overflow-x: scroll;
    .table-grid {
      min-width: 1500px;
      .grid-th {
        @include grids();
        font-size: 10px;
        color: $font-secondary;
        & > div {
          padding: 10px 5px;
        }
      }

      .grid-tbody {
        @include grids();
        font-size: 12px;
        background-color: #ffffff;
        margin-bottom: 5px;
        color: $font-primary;
        & > div {
          padding: 10px 5px;

          .badges {
            margin-top: 5px;
          }
          .expired_at {
            font-size: 10px;
          }

          img {
            border-radius: 4px;
          }
        }

        &-full {
          width: 100%;
          padding: 10px 5px;
          text-align: center;
          background-color: #ffffff;
        }
      }

      .grid-expand-table {
        width: 100%;
        background-color: #ffffff;
        margin-bottom: 5px;
        margin-top: -4px;
        padding: 10px 5px;

        &-grid-th {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 80px;
          font-size: 12px;
          color: $font-secondary;
          & > div {
            padding: 10px;
          }
        }

        &-grid-tbody {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 80px;
          background-color: #f1f4f6;
          margin-bottom: 5px;
          font-size: 12px;
          & > div {
            padding: 10px;

            img {
              border-radius: 4px;
            }

            button i {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}
