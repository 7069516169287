$app_name:hero;
@import "./src/styles/variables";

.login {
  &-page {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-image: url("https://storage.googleapis.com/asia.artifacts.gm-gettmore-project.mtb.co.th/hero-bg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  &-form {
    flex: 0 0 25%;
    max-width: 25%;
    min-width: 350px;
    border-radius: 20px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
      0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03), 0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
      0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
  }

  &-header {
    background-color: $primary-color;
    padding: 24px 8px 8px;
    color: #ffffff;
    text-align: center;
    position: relative;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &-icon {
    font-size: 3rem;
    position: absolute;
    top: -24px;
    background: #9883e5;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    border-radius: 50%;
    padding: 5px;
  }

  &-wrapper {
    padding: 24px 16px 16px;
    background-color: #ffffff;
    border-radius: 20px;

    hr {
      margin: 16px 0;
    }

    &-logo {
      padding: 10px 20px;
      text-align: -webkit-center;
      img {
        height: 50px;
      }
    }

    h1 {
      text-align: center;
      font-weight: 500;
      color: $font-primary;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 2rem;
        margin-right: 5px;
      }
    }

    p {
      text-align: center;
      margin-bottom: 24px;
      color: $font-secondary;

      &.remark-text {
        text-align: left;
      }
    }

    button {
      span {
        i {
          margin-top: 3px;
        }
      }

      margin-bottom: 30px;
    }
  }
}
