$app_name:hero;
@import "./src/styles/variables";
.section-header {
  display: flex;
  border-bottom: solid 1px $border-color;
  padding: 0.5rem 0;
  justify-content: space-between;
  align-items: center;

  &.border-none {
    border-bottom: none;
  }

  h1 {
    font-size: 20px;
  }

  &-breadcrumb {
    ul {
      li {
        // Cancel the rule in src/pages/home/policy.scss
        &:before {
          content: none;
          margin-right: 0;
        }

        a {
          font-size: 11px;
          color: $label-color;
        }
      }
    }
  }
}
