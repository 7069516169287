$app_name:hero;
@import "../../../styles/variables";
.gm-dateTimepicker {
  position: relative;

  i {
    position: absolute;
    z-index: 1;
    right: 6px;
    top: 6px;
    color: rgba(0, 0, 0, 0.65);
  }

  .react-datepicker {
    border: none;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    &-wrapper {
      width: 100%;
    }
    &__input-container {
      input {
        width: 100%;
        padding: 0.375rem 0.75rem;
        border: 1px solid rgba($color: #838383, $alpha: 0.5);
        outline: none;
        color: rgba(0, 0, 0, 0.65);
        font-size: 0.7rem;
        font-weight: 100;
        color: $font-primary;
        line-height: 1.5;
        display: block;
        transition: all 0.1s ease-in;
        height: 2.3rem;

        &:read-only {
          background-color: rgba($color: #cccccc, $alpha: 0.1);
          cursor: not-allowed;
        }

        &:focus {
          border-color: $primary-color;
          outline: 0;
          box-shadow: 0 0 0 0.1rem rgba($color: $primary-color, $alpha: 0.3);
        }
      }
    }
    &__header {
      background-color: $primary-color;
    }
    &__navigation {
      &--previous {
        border-right-color: #ffffff;
      }
      &--next {
        border-left-color: #ffffff;
        right: 10px;
      }
    }

    &__current-mont {
        color: #ac2d2d;
    }

    &__time-container {
      position: absolute;
      right: -85px;
    }
  }
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    color: #ffffff;
}

.react-datepicker__header .react-datepicker__day-name, .react-datepicker__header .react-datepicker__day, .react-datepicker__header .react-datepicker__time-name {
    color: #ffffff;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range {
    background-color: $primary-color;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover {
    background-color: rgba($color: $primary-color, $alpha: .7);
}

.react-datepicker__year-read-view, .react-datepicker__month-read-view, .react-datepicker__month-year-read-view {
  border: 1px solid #fff;
  border-radius: 0.3rem;
  background: #fff;
  padding: 2px;
}