$app_name:hero;
@import "../../../src/styles/variables";
@import "./checkmark";

.checkbox-component {
  position: relative;
  &-button {
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 30px;
    position: relative;
    text-align: left;
    padding-left: 25px;
    font-size: 1rem;
    outline: none;
    align-items: center;

    .check-icon {
      width: 20px;
      height: 20px;
      margin-left: 8px;
      margin-right: 8px;
      overflow: hidden;
      display: block;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .checkmark {
      @include checkmark-styles;
    }
    &:focus {
      @include button-focus;
    }
    &.checked {
      @include button-checked;
    }
  }
}
