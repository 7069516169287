$app_name:hero;
@import "./src/styles/variables";
.agent-order-detail {
  padding: 0;

  .sticky {
    position: sticky;
    top: 60px;
    z-index: 1;
  }

  &-header {
    display: grid;
    grid-template-columns: 1fr 150px;
    align-items: center;
    small {
      color: $font-secondary;
    }
  }

  &-sub-header {
    display: grid;
    grid-template-columns: 25% 25% 25% 1fr;
    align-items: center;
    small {
      color: $font-secondary;
    }
  }

  &-wrapper {
    padding: 8px 16px;

    .bg {
      background-color: #f1f2f3;
      padding: 8px;
    }

    .list-content,
    .list-content-2 {
      padding: 0 16px;

      .label.type-1 {
        color: $font-secondary;
      }

      .label.type-2 {
        small {
          color: $font-secondary;
        }
      }

      .bold {
        font-weight: 500;
      }

      ul {
        padding-left: 20px;
        li {
          margin-top: 8px;
          list-style: disc;
        }
      }

      .bd-dotted {
        margin-top: 16px;
        border-bottom: dotted 2px #dddddd;
      }
    }

    .list-content-2 {
      padding: 0;
    }

    .btn-pay-slip {
      margin-left: 16px;
      display: inline-block;

      i {
        font-size: 1rem;
      }
    }
  }
}
