$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/grid";

.motor {
  &-mainpage {
    padding: 16px;
    background-image: url("https://storage.googleapis.com/asia.artifacts.gm-gettmore-project.mtb.co.th/hero-bg-home.jpg");
    background-position: top center;
    background-size: cover;
    min-height: 100vh;

    h3 {
      margin-top: 110px;
      font-size: 32px;
      margin-bottom: 20px;
    }

    .motor-tab {
      button.tab-btn {
        padding: 16px 16px 8px 16px;
        border-top-left-radius: 13px;
        border-top-right-radius: 13px;
        margin-right: 4px;
        outline: none;
        background-color: #dee0e3;
        color: #656e74;
        // transition: all 0.2s ease-in-out;

        i {
          font-size: 1.1rem;
          margin-right: 8px;
        }

        &.active {
          background-color: #ffffff;
          @apply text-secondary;
          font-weight: 700;
          padding-top: 18px;

          i {
            font-size: 1.2rem;
          }
        }
      }

      .tab-content {
        background-color: #ffffff;
        border-radius: 13px;
        border-top-left-radius: 0;
        overflow: hidden;
        box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
          0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
          0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
          0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
      }
    }

    &-searchbox {
      background-color: #ffffff;
      padding: 60px 30px 30px 30px;
      border-radius: 13px;
      border-top-left-radius: 0;

      &-radio {
        margin-top: 16px;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;

        .switcher {
          display: flex;
          margin-bottom: 1.5rem;

          label {
            padding: 5px 16px;
            border: solid 1px rgba($color: #000000, $alpha: 0.4);
            cursor: pointer;
            position: relative;
            transition: all 0.2s ease-in;

            &:first-child {
              border-right: none;
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
            }

            &:last-child {
              border-left: none;
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
            }

            &.active {
              background-color: $secondary-color;
              color: #ffffff;

              &:first-child {
                border: solid 1px rgba($color: $secondary-color, $alpha: 0.4);
                border-right: none;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
              }

              &:last-child {
                border: solid 1px rgba($color: $secondary-color, $alpha: 0.4);
                border-left: none;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
              }
            }

            input {
              position: absolute;
              width: 100%;
              height: 100%;
              opacity: 0;
            }
          }
        }
      }

      &-checkbox {
        margin-bottom: 1rem;
        align-items: center;
        display: flex;

        label {
          margin-left: 16px;
        }

        .box {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(120px, 0fr));
        }
      }

      &-fields {
        display: grid;
        grid-gap: 1rem;
        margin-right: 5px;

        @media (max-width: 725px) {
          grid-template-columns: 1fr 1fr 1fr;
        }

        &.cmi {
          grid-template-columns: 1fr 1fr 15% 1fr;
        }

        &.cmi-adjust {
          grid-template-columns: 1fr 1fr 15% 1fr 1fr 1fr;
        }

        &.vmi {
          grid-template-columns: 1fr 1fr 15% 1fr 1fr;
        }

        &.vmi-adjust {
          grid-template-columns: 1fr 1fr 15% 1fr 1fr 1fr;
        }
      }

      .from-group {
        // text-align: center;

        button {
          padding-left: 46px;
          padding-right: 46px;
        }
      }

      .radio-button {
        button {
          padding: 0px !important;
        }
      }
    }
  }
}