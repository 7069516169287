$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/grid";
.search-card {
  background-color: #ffffff;
  padding: 16px;
  border-radius: 4px;
  margin-top: 16px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
    0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03), 0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
    0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
  transition: all 0.2s ease-in-out;

  &.zoom-in {
    transform: scale(1.02);
  }

  .card-header {
    display: flex;
    .img {
      width: 50px;
      height: 50px;
      background-color: rgba($color: #cccccc, $alpha: 0.2);
      border-radius: 4px;
      margin-right: 16px;
      overflow: hidden;
    }

    .insurer {
      margin-top: 5px;
      &-name {
        font-size: 1.1rem;
      }
      &-type {
        font-size: 0.8rem;
      }
    }
  }

  .card-price {
    // display: grid;
    // grid-template-columns: 1fr 60px;
    // margin-top: 8px;
    // border-bottom: solid 2px rgba($color: #cccccc, $alpha: 0.2);
    // padding-bottom: 16px;
    display: flex;
    margin-top: 8px;
    border-bottom: solid 2px rgba($color: #cccccc, $alpha: 0.2);
    padding-bottom: 16px;
    flex-wrap: wrap;
    justify-content: space-between;

    .price {
      span {
        font-size: 1.2rem;
        font-weight: 700;
        margin-right: 5px;
        color: $secondary-color;
      }
    }

    .comission {
      margin-top: 5px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      span.box {
        background-color: $primary-color;
        color: #ffffff;
        padding: 2px 10px;
        border-radius: 10px;
        font-size: 0.9rem;
      }
    }

    .actions {
      text-align: right;

      button {
        outline: none;
        i {
          display: block;
          font-size: 1.5rem;
        }
        font-size: 0.75rem;

        &.active {
          color: $primary-color;
        }
      }
    }
  }

  // .comission {
  //   margin-top: 5px;
  //   span {
  //     background-color: $secondary-color;
  //     color: #ffffff;
  //     padding: 2px 10px;
  //     border-radius: 10px;
  //     font-size: 0.9rem;
  //   }
  // }

  .card-coverage {
    padding-top: 16px;
    padding-bottom: 16px;

    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        font-size: 0.8rem;
      }
      li.key {
        // Cancel the rule in src/pages/home/policy.scss
        &:before {
          content: none;
          margin-right: 0;
        }

        flex-basis: 65%;
        padding-right: 8px;
      }

      li.value {
        // Cancel the rule in src/pages/home/policy.scss
        &:before {
          content: none;
          margin-right: 0;
        }

        flex-basis: 35%;
        text-align: right;
      }
    }
  }

  .card-actions {
    display: flex;
    flex-wrap: wrap;

    .more-detail {
      flex-basis: 100%;
    }

    .buy-button {
      flex-basis: 100%;
      margin-top: 8px;
    }
  }
}
