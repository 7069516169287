$primary-color: #eb008b;
$secondary-color: #112e42;

// fix code better
@if $app_name == 'mti' {
  $primary-color: #0194D3;
  $secondary-color: #1C4C7F;
}@else {
  $primary-color: #eb008b;
  $secondary-color: #112e42;
}

$success-color: #4caf50;
$error-color: #eb3f4d;
$grey-color: #c4c4c4;

$policy-success-bg: #e9f7fc;
$policy-success-color: #11647e;

$bg-main: #f1f4f6;

$input-border: #ced4da;

$table-header-color: #959595;

$status-pending-color: #f4d843;
$status-failed-color: #e8556b;
$status-success-color: #54c1bb;
$status-cancel-color: #c4c4c4;
$status-waiting-color: #ffae14;

$padding-space: 16px;
$box-shadow: 1px 3px 6px #dee0e380;
$radius: 4px;

//fonts
$main-family: "Kanit", sans-serif;
$main-family2: "Kanit", sans-serif;
$h1: 1.5rem;
$h2: 3.75rem;
$h3: 3rem;
$h4: 1rem;
$h5: 1.5rem;
$h6: 1.25rem;
$body-font-1: 1rem;
$body-font-2: 0.875rem;
$caption: 0.75rem;

$font-primary: #111;
$font-secondary: #848484;
$label-color: #9a9ea1;
$border-color: #dddddd;
$table-color: #41474e;
$header2-color: #41474e;

$swatch1-1: #fdfdfd;
$swatch1-2: #9cddc7;
$swatch1-3: #6d74fe;
$swatch1-4: #7686fe;
$swatch1-5: #5f3b28;
$swatch1-6: #56a8c2;
$swatch1-7: #5664a9;

$btn-save-comm: $swatch1-3;
$btn-save-comm-font: $swatch1-1;
$input-bg-comm: rgba($swatch1-2, 0.4);
$btn-create-quote: $swatch1-6;
$btn-save-comm: $swatch1-3;
$btn-save-comm-font: $swatch1-1;
$input-bg-comm: rgba($swatch1-2, 0.4);

$compare-bar-bg: #dddddd;

$ph-bg: #fff !default;
$ph-color: rgb(241, 241, 241) !default;
$ph-border-radius: 4px !default;

$ph-gutter: 16px !default;
$ph-spacer: 8px !default;

$ph-avatar-border-radius: 50% !default;
$ph-animation-duration: 0.8s !default;

$side-bar-color: #112e42;
$side-bar-toggle-color: #41474e;

// constants
$header-height: 64px;
