$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/grid";

.view-quotation {
  padding: 16px;

  &-title {
    font-size: 11px;
    color: #848484;
    .title-border {
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 10px;
      color: #000000c1;
    }

    .title-bottom {
      font-size: 12px;
      font-weight: 200;
      color: #949494;
      padding-top: 5px;
    }
  }

  &-component {
    margin-top: 1.5rem;
    // background-color: #ffffff;
    // box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
    //   0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
    //   0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
    //   0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
    transition: all 0.2s ease-in-out;
    border-radius: 4px;
    color: $font-primary;
    font-family: $main-family2;

    .title {
      display: grid;
      grid-template-columns: 3% 20% 5% 1fr 19% 10% 17%;
      justify-items: center;
      font-weight: normal;
      font-size: 1rem;
      color: $table-header-color;
      padding-top: 1rem;
      padding-bottom: 0.5rem;
      border-bottom: 2px solid rgba($color: #cccccc, $alpha: 0.5);
    }

    .show-quotation-info {
      display: grid;
      padding: 10px 0px;
      grid-template-columns: 3% 20% 5% 1fr 19% 10% 17%;
      justify-items: center;
      align-items: center;
      font-weight: 400;
      font-size: 0.9rem;
      border-bottom: 1px solid #f3f3f3;
    }

    .expand-on {
      color: #ffffff;
      outline: none;
      white-space: normal;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: $secondary-color;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 1.2rem;
      }
    }

    .expand {
      color: #ffffff;
      outline: none;
      white-space: normal;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      border: 1.3px solid rgba($color: $grey-color, $alpha: 1);
      background-color: #ffffff;
      color: $grey-color;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 1.2rem;
      }
    }

    .copyURL-btn {
      background: #f1f2f3;
      color: #575757;
      border: 1.3px solid rgba($color: #f1f2f3, $alpha: 1);
      padding: 0.2rem 0.8rem;
      font-size: 0.8rem;
      font-weight: 300;
      outline: none;
      border-radius: 4px;
      margin-top: 10px;
    }

    .img {
      width: 40px;
      height: 40px;
      background-color: #ececec;
      font-size: 0.6rem;
      border-radius: 50%;
    }

    .phone {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 0.15rem;
      i {
        &::before {
          font-size: 0.8rem;
        }
        font-size: 1.2rem;
        margin-right: 8px;
      }
    }

    .text-error {
      color: $error-color;
    }

    .table-grid {
      .grid-th {
        display: grid;
        grid-template-columns: 40px 1fr 1fr 1fr 1fr 1fr 1fr 100px;
        font-size: 12px;
        color: $font-secondary;
        & > div {
          padding: 10px 5px;
        }
      }

      .grid-tbody {
        display: grid;
        grid-template-columns: 40px 1fr 1fr 1fr 1fr 1fr 1fr 100px;
        font-size: 12px;
        background-color: #ffffff;
        margin-bottom: 5px;
        color: $font-primary;
        & > div {
          padding: 10px 5px;
        }

        &-full {
          width: 100%;
          padding: 10px 5px;
          text-align: center;
          background-color: #ffffff;
        }
      }

      .grid-expand-table {
        width: 100%;
        background-color: #ffffff;
        margin-bottom: 5px;
        margin-top: -4px;
        padding: 10px 5px;

        &-grid-th {
          display: grid;
          grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 100px;
          font-size: 12px;
          color: $font-secondary;
          & > div {
            padding: 10px;
          }
        }

        &-grid-tbody {
          display: grid;
          grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 100px;
          background-color: #f1f4f6;
          margin-bottom: 5px;
          font-size: 12px;
          & > div {
            padding: 10px;

            img {
              border-radius: 4px;
            }

            button i {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }

  .download-btn {
    color: #575757;
    font-size: 12px;
    font-weight: 300;
    padding: 4px;
    outline: none;
    border: 1px solid #f1f2f3;
    border-radius: 4px;

    * {
      pointer-events: none;
    }
    &.disable {
      opacity: 0.5;
    }
    span {
      padding-right: 0.5rem;
    }
    i {
      vertical-align: middle;
    }
  }

  .img-phone {
    margin: 10px;
  }
}
