$app_name:hero;
.vdo-section {
  overflow: hidden;
  background-color: #fff;
  &::after {
    content: "";
    position: absolute;
    width: 120%;
    height: 60px;
    @apply bg-grayCustom-3;
    bottom: 0;
    left: -20%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
  }

  .vdo-thumb {
    & > .bg {
      content: "";
      position: absolute;
      width: 100%;
      height: 80%;
      top: 10%;
      left: 0;
      border-radius: 60px;
      background: transparent linear-gradient(180deg, #de168b 0%, #d5339d 100%)
        0% 0% no-repeat padding-box;

      @media (max-width: 1200px) {
        border-radius: 60px;
        position: absolute;
        width: 130%;
        left: -15%;
      }

      @media (max-width: 1023px) {
        border-radius: 0px;
        position: absolute;
        width: 500%;
        left: -100%;
      }
    }

    &-image {
      width: 1000px;
    }

    .play-button {
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      outline: none;

      @media (max-width: 1023px) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 20%;
      }
    }
  }

  .video-modal {
    max-width: 1280px;
    width: 100%;
    position: relative;
  }
  .video-container {
    position: relative;
    padding-bottom: 56.25%; /*16:9*/
    // padding-top: 30px;
    height: 0;
    overflow: hidden;
    width: 100%;
  }

  .video-container iframe,
  .video-container object,
  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.heartbeat {
  -webkit-animation: heartbeat 3s ease-in-out infinite both;
  animation: heartbeat 3s ease-in-out infinite both;
}

@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}
