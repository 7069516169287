$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/grid";
.motor {
  &-info-page {
    padding: 16px;
    h1 {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        margin-right: 8px;
      }
    }

    h2 {
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      font-size: 1.25rem;
    }

    .help-txt {
      color: rgba($color: #333333, $alpha: 0.4);
    }

    .top-msg {
      margin-bottom: 1rem;
      background-color: rgba($color: $error-color, $alpha: 0.8);
      border: solid 1px $error-color;
      color: #ffffff;
      padding: 0.5rem 1rem;
      border-radius: 4px;
    }

    &-card {
      background-color: #ffffff;
      padding: 16px 32px;
      border-radius: 4px;
      margin-top: 16px;
      box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
        0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
        0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
        0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
    }

    &-actions {
      margin-top: 1rem;
      margin-bottom: 1rem;
      display: flex;
      justify-content: flex-end;

      &.space-between {
        justify-content: space-between;
      }
    }
  }
  &-info-btn-dummy {
    position: absolute;
    right: 1rem;
    font-size: 0.875rem;
    color: darkcyan;
    opacity: 0.7;
    text-decoration: underline;
  }

  &-info-btn-dummy-switch {
    position: absolute;
    right: 1rem;
    font-size: 0.875rem;
    color: #000000;
    align-items: center;
    font-weight: 500;
  }
}

.vehicle-registration {
  &-box {
    display: flex;
    justify-content: center;
  }
  &-layout {
    width: 80%;
    box-shadow: 0px 3px 6px #00000029;
    border: 3px solid #4f4f4f;
    border-radius: 9px;
    padding: 5px;
    font-family: "Sarabun", sans-serif;
    margin-top: 1rem;

    .top {
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
      min-height: 50px;
    }

    .bottom {
      font-size: 1rem;
      font-weight: 700;
      text-align: center;
    }
  }
}

.image-preview {
  width: 100%;
  height: auto;
  background-color: #eeeeee;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 16px;
  // box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
  //   0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
  //   0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
  //   0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
  border: 1px solid rgba(204, 204, 204, 0.5);
  img {
    max-width: 100%;
  }
}

.delete-field {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
  color: rgba($color: $error-color, $alpha: 1);
  font-size: 0.9rem;

  button {
    display: flex;
    outline: none;
    &:focus {
      outline: none;
    }

    i {
      font-size: 1.2rem;
    }
  }
}
