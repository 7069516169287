$container-width: 100%;
$grid__cols: 12;

$grid__bp-sm: 576;
$grid__bp-md: 768;
$grid__bp-lg: 992;
$grid__bp-xl: 1200;
$map-grid-props: (
  "": 0,
  "-sm": $grid__bp-sm,
  "-md": $grid__bp-md,
  "-lg": $grid__bp-lg,
  "-xl": $grid__bp-xl,
);

.container {
  width: ($grid__bp-xl * 1px);
  margin: auto;
}

.container-compare {
  width: ($grid__bp-lg * 1px);
  margin: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
}

.row-inline {
  display: flex;
  flex-wrap: nowrap;
  margin-left: -8px;
  margin-right: -8px;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.no-padding {
  padding: 0 !important;
}

.d-flex {
  display: flex;
}

@mixin create-mq($breakpoint) {
  // WORKAROUND: Node 16.14.x: Top-level selectors may not contain the parent selector "&".
  @if ($breakpoint == 0) {
    @media (min-width: $breakpoint *1px) {
      @content;
    }
  } @else {
    @media screen and (min-width: $breakpoint *1px) {
      @content;
    }
  }
}

@mixin create-col-classes($modifier, $grid__cols, $breakpoint) {
  @include create-mq($breakpoint) {
    @for $i from 1 through $grid__cols {
      &.col#{$modifier}-#{$i} {
        flex: 0 0 (100 / ($grid__cols / $i)) * 1%;
        max-width: (100 / ($grid__cols / $i)) * 1%;
        // flex-basis: (100 / ($grid__cols / $i)) * 1%;
        // max-width: (100 / ($grid__cols / $i)) * 1%;
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }
}

@each $modifier, $breakpoint in $map-grid-props {
  @include create-col-classes($modifier, $grid__cols, $breakpoint);
}
