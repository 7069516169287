$app_name:hero;
.text-indent-home {
  text-indent: 2rem;
}

.page {
  width: 210mm;
  margin-left: auto;
  margin-right: auto;
}

ul {
  list-style-type: none;
}

ul li:before {
  margin-right: 1em;
}
