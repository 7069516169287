$app_name:hero;
@import "../../../src/styles/variables";

.select-component {
  position: relative;

  &-button {
    padding: 6px 16px;
    padding-right: 32px;
    border: solid 1px #c4c4c4;
    margin: 0 4px;
    border-radius: 30px;
    background-color: #ffffff;
    outline: none;
    font-size: 1rem;
    min-width: 120px;
    display: flex;
    align-items: center;
    font-weight: 400;
    position: relative;

    @media screen and (max-width: 1024px) {
      font-size: 0.8rem;
    }

    &:focus,
    &.active {
      @apply border border-primary outline-none;
    }

    span.icon {
      position: absolute;
      right: 10px;
      color: $font-primary;
    }
  }

  &-list {
    width: 100%;
    height: auto;
    left: 8px;
    top: 48px;
    background-color: #ffffff;
    position: absolute;
    z-index: 1;
    border: solid 1px #dddddded;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 1rem;
    z-index: 100;

    &.fix-width {
      width: 250px;
    }
    &.overflow {
      overflow-y: scroll;
      height: 350px;
    }
    &.fix-width-2 {
      width: 200px;
    }
    &.right {
      left: unset;
      right: 8px;
    }
  }
}
