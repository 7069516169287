$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/grid";

.coverage {
  &-details {
    // padding: 0.5rem 1rem;
    font-size: 1rem;
    color: $font-primary;

    h4 {
      color: $secondary-color;
    }

    .cycle {
      font-size: 0.2rem;
      margin-right: 0.5rem;
      color: $grey-color;
    }
  }
  &-price {
    width: 100%;
    font-size: 0.9375rem;
    color: #242424;
    .line {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.75rem;
    }
  }
}
