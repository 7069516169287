$app_name:hero;
@import "../../../styles/variables";
.location-search {
  position: relative;
  &-input {
    width: 100%;
    padding: 0.375rem 0.75rem;
    border: 1px solid rgba($color: #cccccc, $alpha: 0.5);
    outline: none;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 1rem;
    line-height: 1.5;
    display: block;
    transition: all 0.1s ease-in;

    &:read-only {
      background-color: rgba($color: #cccccc, $alpha: 0.1);
      cursor: not-allowed;
    }

    &:focus {
      border-color: $primary-color;
      outline: 0;
      box-shadow: 0 0 0 0.1rem rgba($color: $primary-color, $alpha: 0.3);
    }

    &.has-error {
      border-color: $error-color;
      &:focus {
        border-color: $error-color;
        outline: 0;
        box-shadow: 0 0 0 0.1rem rgba($color: $error-color, $alpha: 0.3);
      }
    }

    &-error {
      color: $error-color;
      font-size: 0.8rem;
      display: flex;
      align-items: center;

      & i {
        font-size: 1rem;
        margin-right: 5px;
      }
    }

    &-items {
      border: 1px solid rgba($color: #cccccc, $alpha: 0.5);
      background-color: #ffffff;
      position: absolute;
      width: 100%;
      max-height: 250px;
      overflow-y: scroll;
      z-index: 9;
      box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
        0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03), 0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
        0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);

      li {
        button {
          display: block;
          align-items: center;
          padding-left: 16px;
          padding-right: 16px;
          padding-bottom: 12px;
          text-align: left;
          width: 100%;
          font-size: 0.8rem;

          i {
            position: relative;
            top: 7px;
          }

          &:hover {
            background-color: rgba($color: #cccccc, $alpha: 0.5);
          }
        }
      }
    }
  }

  ul li {
    // Cancel the rule in src/pages/home/policy.scss
    &:before {
      content: none;
      margin-right: 0;
    }
  }
}
