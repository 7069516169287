$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/grid";
.search-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba($color: #000, $alpha: 0.8);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;

  &-wrapper {
    background-color: #ffffff;
    width: 1200px;
    height: 95vh;
    border-radius: 4px;
    padding: 16px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
      0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
      0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
      0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);

    &-header {
      // border-bottom: 1px solid #ccc;
      padding-bottom: 16px;
      position: relative;
      display: flex;
      &-close {
        position: absolute;
        top: 0;
        right: 0;
        outline: none;

        &:active,
        &:focus {
          outline: none;
        }
      }

      &-left,
      &-right {
        flex-basis: 50%;
      }

      &-action {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 40px;

        .label {
          margin-left: 8px;
          color: #666;
        }
      }
    }

    &-content {
      background-color: $bg-main;
      border-radius: 4px;
      padding: 16px 16px;
      height: calc(95vh - 130px);
      overflow-x: scroll;
    }
  }
}
