$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/grid";

.travel-search-page {
  padding: 16px;

  h1 {
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    i {
      font-size: 2rem;
      margin-right: 8px;
      color: $primary-color;
    }
  }

  &-count {
    color: rgba($color: #333333, $alpha: 0.9);
    .lenght {
      font-weight: 700;
      color: $secondary-color;
      font-style: italic;
    }
    .model {
      margin-left: 5px;
      font-style: italic;
      text-decoration: underline;
      color: #000000;
    }
  }

  &-tools {
    display: flex;
    justify-content: flex-end;
    color: rgba($color: #333333, $alpha: 0.9);

    .toggle-commission {
      flex-basis: 100%;
      display: flex;
      justify-content: flex-end;

      .label {
        margin-left: 8px;
      }
    }
  }

  &-fillter {
    &-box {
      background-color: #ffffff;
      padding: 16px;
      border-radius: 4px;
      margin-top: 16px;
      box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
        0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
        0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
        0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);

      h2 {
        font-size: 1rem;
      }
    }
  }
}
