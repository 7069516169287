.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9990;
  background-color: rgba($color: #000000, $alpha: 0.8);
  display: flex;
  align-items: center;
  justify-content: center;

  &-overlay {
    position: fixed;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: wait;
  }

  &-box {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    // height: 40vh;
    // min-height: fit-content;
    background-color: #ffffff;
    padding: 0.5rem 2rem;
    border-radius: 15px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
      0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03), 0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
      0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
    text-align: center;

    .header {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      // justify-content: space-around;
      column-gap: 1rem;

      .icon {
        text-align: center;
        // margin-top: 2rem;
        .material-icons {
          color: #c8c7c7;
          // color: #f8f7f7;
          font-size: 2.5rem;
          // font-size: 5rem;
        }
      }

      .buttons {
        text-align: right;
      }

      .close-icon {
        font-size: 1.2rem;
        // outline: none;
        padding: 0.4rem 0.4rem 0rem;

        // position: absolute;
        // right: 16px;
        // top: 16px;

        &:active {
          outline: none;
        }
      }
    }

    .scrollable-content {
      flex-grow: 1;
      max-height: 75vh;
      overflow: scroll;
    }

    h2 {
      color: $font-primary;
      text-align: center;
      font-size: 2.5rem;
    }

    h3 {
      color: $font-primary;
      text-align: center;
      font-size: 0.8rem;
      font-weight: 300;
      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }

    h4 {
      color: $font-primary;
      text-align: left;
      font-size: 1rem;
      font-weight: 700;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    hr {
      background: #e6e6e6;
    }
  }
}
