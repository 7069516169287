$app_name:hero;
@import "./src/styles/variables";

.main-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  height: $header-height;
  background-color: #ffffff;
  z-index: 1000;

  &-logo-main {
    flex-basis: 30%;
    padding-left: 16px;
    height: 50px;
    margin-top: 15px;

    a {
      display: block;

      img {
        height: 31px;
      }
    }
  }

  &-actions {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-right: 16px;
    align-items: center;

    &>div {
      margin-left: 16px;
    }

    .username {
      .avatar {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background-color: #cccccccc;
        margin-right: 8px;
        text-align: center;
        font-size: 1.1rem;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
      }
    }

    button {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      color: #ffffff;
      border: 1px solid rgba($color: #41474e, $alpha: 0.8);
      background-color: rgba($color: #41474e, $alpha: 1);
      box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
        0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
        0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
        0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease-in;

      span {
        font-size: 1.5rem;
      }

      &:hover {
        background-color: rgba($color: #e5626c, $alpha: 0.9);
        border: 1px solid rgba($color: #e5626c, $alpha: 0.8);
      }
    }
  }
}