$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/grid";

.checkout {
  &-summary {
    padding: 1rem;

    h1 {
      text-align: center;
    }

    h2 {
      margin-top: 0.5rem;
      margin-bottom: 1.25rem;
      // color: $secondary-color;
    }

    .top-msg {
      margin-bottom: 1rem;
      background-color: rgba($color: $error-color, $alpha: 0.8);
      border: solid 1px $error-color;
      color: #ffffff;
      padding: 0.5rem 1rem;
      border-radius: 4px;
    }

    &-card {
      background-color: #ffffff;
      padding: 16px 32px;
      border-radius: 4px;
      margin-top: 16px;
      box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
        0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
        0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
        0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
    }
    &-group {
      display: grid;
      grid-template-columns: 200px 1fr;
      grid-column-gap: 1rem;
      grid-row-gap: 0.33rem;
      font-family: $main-family2;
      letter-spacing: 0.5px;
      margin-top: 2rem;
      font-weight: 300;

      h4 { 
        grid-column: 1 / -1; 
        font-weight: 700;
        color: #000000;
        text-decoration: none;
        padding-bottom: 0.25rem;      
        margin-bottom: 0.5rem;

        &.primary {
          color: $primary-color;
        }

        &.secondary {
          color: $secondary-color;
        }

        span {
          margin-right: 0.5rem;

          &.material-icons {
            position: relative;
            top: 6px;
          }
        }
      }
      .field-title {
        font-weight: 300;
        color: #545454;
        font-size: 0.9rem;
      }
      .field-value {
        font-weight: 700;
        font-size: 0.9rem;
      }
    }
  }
}

.cmi-head {
  font-weight: 500;
  color: darkgoldenrod;
  margin-bottom: 0.5rem;
}

.motor {
  &-summary-page {
    padding: 16px;

    h2 {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      color: $secondary-color;
    }

    h1 {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        margin-right: 8px;
      }
    }
    .top-msg {
      margin-bottom: 1rem;
      background-color: rgba($color: $error-color, $alpha: 0.8);
      border: solid 1px $error-color;
      color: #ffffff;
      padding: 16px;
      border-radius: 4px;
    }

    &-card {
      background-color: #ffffff;
      padding: 16px 32px;
      border-radius: 4px;
      margin-top: 16px;
      box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
        0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
        0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
        0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
    }

    &-list {
      display: flex;

      &.space-bottom {
        margin-bottom: 1rem;
      }

      .key {
        width: 30%;
        font-weight: 500;
      }

      .value {
        color: $font-secondary;
        font-weight: 400;
      }
    }

    &-actions {
      margin-top: 3rem;
      margin-bottom: 1rem;
      display: flex;
      justify-content: flex-end;

      &.space-between {
        justify-content: space-between;
      }
    }
  }
}
