$app_name:hero;
@import "./src/styles/_variables";

.alert-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba($color: #000000, $alpha: 0.9);
  display: flex;
  justify-content: center;
  align-items: center;

  &-box {
    width: 350px;
    min-height: 80px;
    background-color: rgba($color: $error-color, $alpha: 1);
    border-radius: 4px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
      0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
      0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
      0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
    padding: 16px;
    position: relative;

    &-icon {
      text-align: center;
      width: 25px;
      height: 25px;
      position: absolute;
      top: -13px;
      left: 16px;
      background-color: #ffffff;
      border-radius: 50%;
      padding: 3px;
      i {
        font-size: 1.3rem;
        color: $error-color;
      }
    }

    &-content {
        color: #ffffff;
        padding-top: 8px;
    }

    &-button {
        color: #ffffff;
        padding-top: 8px;
        text-align: right;
        
        button {
            border: solid 2px #ffffff;
            padding: 2px 8px;
            min-width: 80px;
            border-radius: 4px;
        }
    }
  }
}
