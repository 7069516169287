$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/grid";

.renew-motor-form {
  .overflow-scoll {
    // height: calc(100vh - 300px);
    // overflow-y: auto;
  }
  h2 {
    text-align: center;
    color: $header2-color;
    margin-bottom: 1rem;
  }

  h4 {
    color: #000000;
    font-size: 12px;
    margin-bottom: 0.5rem;
  }

  .policy-form-label {
    font-size: 12px;
    color: #646e78;
    margin-left: 8px;

    span {
      color: #dc3545;
    }
  }

  .space-input {
    padding: 0;
    font-size: 12px;
    color: #646e78;
  }

  .space-bottom {
    margin-bottom: 8px;
  }

  .end-section {
    margin: 16px 0;
    border: none;
    margin-left: 8px;
    position: relative;
    padding-bottom: 4px;
    &::after {
      content: "";
      border-bottom: dotted 2px #c4c4c4cb;
      width: 100%;
      height: 2px;
      position: absolute;
    }
  }

  &-btn {
    padding: 8px 0 0;
    display: flex;
    justify-content: center;

    button {
      margin-left: 8px;
      margin-right: 8px;
    }
  }
}

.vehicle-registration-layout {
  width: 100%;
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16078);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16078);
  border: 3px solid #4f4f4f;
  border-radius: 9px;
  padding: 18px;
  font-family: "Sarabun", sans-serif;
  margin-top: 20px;
}
