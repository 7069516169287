$app_name:hero;
@import "./src/styles/variables";

.old-table {
    padding: 1rem;
    border-radius: 4px;
    width: 100%;
    font-size: 0.9rem;
    .text-left {
        text-align: left;
        padding-left: 1.5rem;
        &-policy {
            text-align: left;
            font-weight: 500;
            padding-left: 1.5rem;
        }
    }
    .cmi-label {
        background-color: rgba($color: #646e78, $alpha: 0.1);
        border-radius: 8px;
        display: inline-block;
        padding: 1px 2px;
        font-size: 10px;
        width: 30px;
        text-align: center;
      }
}