$app_name:hero;
@import "./src/styles/variables";

.table-overflow {
  overflow-x: scroll;
  overflow-y: hidden;
  font-family: $main-family2;
}

.width-1 {
  width: 90px;
}
.width-2 {
  width: 200px;
}
.width-3 {
  width: 120px;
}
.policy-table {
  width: 100%;
  min-width: 1400px;

  th {
    font-weight: 700;
    font-size: 0.8rem;
    color: $table-header-color;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    border-bottom: 2px solid rgba($color: #cccccc, $alpha: 0.5);
  }

  tbody tr {
    &:nth-child(even) {
      background-color: #f9f9f9;
    }
  }

  td {
    font-size: 0.8rem;
    padding: 10px;
    vertical-align: middle;
    color: #4f4f4f;
    border-bottom: solid 1px rgba($color: #cccccc, $alpha: 0.5);

    &.product-type {
      i {
        font-size: 0.9rem;
      }
    }

    .phone {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0.5rem;

      i {
        font-size: 1.2rem;
        margin-right: 8px;
      }
    }

    .insurer-img {
      width: 40px;
      border-radius: 4px;
      margin: auto;
      box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
        0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
        0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
        0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
    }
  }
}

.quotations-table {
  width: 100%;
  table-layout: fixed;

  th {
    font-weight: 300;
    font-size: 11px;
    color: $table-header-color;
    // padding-top: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: none;
  }

  tbody {
    background-color: #ffffff;
    box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
      0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
      0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
      0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
  }

  tr {
    &.body-border {
      td {
        border-bottom: 5px solid #f1f2f3;
      }
    }
  }

  td {
    font-size: 12px;
    padding: 10px 5px;
    vertical-align: top;
    // color: #4f4f4f;
    border-bottom: 5px solid #f1f2f3;

    &.product-type {
      i {
        font-size: 0.9rem;
      }
    }

    .phone {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0.5rem;

      i {
        font-size: 1.2rem;
        margin-right: 8px;
      }
    }

    .expired_at {
      font-size: 10px;
    }

    .insurer-img {
      width: 25px;
      border-radius: 4px;
      margin: auto;
      box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
        0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
        0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
        0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
    }
  }

  .expend-active {
    td {
      // background-color: #f5f5f5;
      border-bottom: none;
    }
  }

  &-expend {
    width: 100%;
    // background-color: #ffffff;
    margin: 0 8px;

    th {
      font-weight: 300;
      font-size: 0.8rem;
      color: $table-header-color;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      border-bottom: none;

      // &:first-child {
      //   border-left: solid 1px rgba($color: #cccccc, $alpha: 0.5);
      // }

      // &:last-child {
      //   border-right: solid 1px rgba($color: #cccccc, $alpha: 0.5);
      // }
    }

    tbody {
      box-shadow: none;
    }

    td {
      padding: 8px 16px;
      border-top: solid 5px #ffffff;
      border-bottom: solid 5px #ffffff;
      background-color: #f9f9f9;

      // &:first-child {
      //   border-left: solid 1px rgba($color: #cccccc, $alpha: 0.5);
      // }

      // &:last-child {
      //   border-right: solid 1px rgba($color: #cccccc, $alpha: 0.5);
      // }

      img {
        margin: auto;
        width: 40px;
        border-radius: 4px;
        box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
          0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
          0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
          0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
      }
    }
  }

  .quotations-list-expend {
    border-top: 2px solid #f1f2f3;
  }
}
