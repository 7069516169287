$app_name:hero;
@import "../../../styles/variables";

.form-datepicker {
  position: relative;

  .datepicker-toggle {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;

    i {
      position: absolute;
      right: 5px;
      top: 7px;
    }
  }

  input {
    width: 100%;
    padding: 0.375rem 0.75rem;
    border: 1px solid rgba($color: #cccccc, $alpha: 0.5);
    outline: none;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 1rem;
    line-height: 1.5;
    display: block;
    transition: all 0.1s ease-in;

    &:read-only {
      background-color: #ffffff;
      cursor: pointer;
    }

    &:focus {
      border-color: $primary-color;
      outline: 0;
      box-shadow: 0 0 0 0.1rem rgba($color: $primary-color, $alpha: 0.3);
    }

    &.has-error {
      border-color: $error-color;

      &:focus {
        border-color: $error-color;
        outline: 0;
        box-shadow: 0 0 0 0.1rem rgba($color: $error-color, $alpha: 0.3);
      }
    }

    &-error {
      color: $error-color;
      font-size: 0.8rem;
      display: flex;
      align-items: center;

      & i {
        font-size: 1rem;
        margin-right: 5px;
      }
    }
  }

  &-dialog {
    position: absolute;
    margin-top: 0.5rem;
    // padding: 0.5rem;
    border-radius: 4px;
    overflow: hidden;
    z-index: 999;
    background-color: #ffffff;
    box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
      0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
      0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
      0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
  }

  // &:hover {
  //     border: 1px solid $primary-color;
  // }
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar {
  border-radius: 4px;
  max-width: 100%;
  background: white;
  line-height: 1.125em;
  border: 0;
  overflow: hidden;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  font-family: $main-family2;
  font-size: 0.9rem;
}

.react-calendar button:disabled {
  cursor: not-allowed;
  background-color: rgba($color: #cccccc, $alpha: 0.1);
  color: #757575;
}

.react-calendar__navigation {
  display: flex;
}

.react-calendar__navigation button {
  min-width: 44px;
  height: 44px;
  background: $primary-color;
  color: #ffffff;
  font-size: 1rem;
}

.react-calendar__navigation button:disabled {
  background: $primary-color;
  color: #ffffff;
  font-size: 1rem;
}

// .react-calendar__navigation button:enabled:hover,
// .react-calendar__navigation button:enabled:focus {
//   // background-color: #e6e6e6;
// }
.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__tile--active {
  background: $primary-color;
  color: white;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}