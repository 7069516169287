$app_name:hero;
.travel {
  &-main-page {
    padding: 16px;
    background-position: top center;
    background-size: cover;
    padding-top: 80px;
    min-height: 100vh;

    h3 {
      margin-top: 110px;
      font-size: 32px;
      margin-bottom: 20px;
    }

    .choose-another-plan {
      font-size: 16px;
      display: flex;
      float: right;
    }

    .travel-tab {
      button.tab-btn {
        padding: 16px 16px 8px 16px;
        border-top-left-radius: 13px;
        border-top-right-radius: 13px;
        margin-right: 4px;
        outline: none;
        background-color: #dee0e3;
        color: #656e74;
        // transition: all 0.2s ease-in-out;

        i {
          font-size: 1.1rem;
          margin-right: 8px;
        }

        &.active {
          background-color: #ffffff;
          @apply text-secondary;
          font-weight: 700;
          padding-top: 18px;

          i {
            font-size: 1.2rem;
          }
        }
      }

      .tab-content {
        background-color: #ffffff;
        border-radius: 13px;
        border-top-left-radius: 0;
        overflow: hidden;
        box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
          0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
          0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
          0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
      }
    }

    &-searchbox {
      background-color: #ffffff;
      padding: 60px 30px 30px 30px;
      border-radius: 13px;
      border-top-left-radius: 0;
      box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
        0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
        0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
        0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);

      &-radio {
        margin-top: 16px;
        margin-bottom: 16px;

        &-label {
          margin-right: 16px;
        }
      }
    }
  }
}
