$app_name:hero;
.agent-section {
  min-height: 850px;
  @media (max-width: 1200px) {
    min-height: auto;
    padding-bottom: 30%;
  }
  @media (min-width: 1600px) {
    min-height: 1000px;
  }
  @apply relative bg-grayCustom-3;
}

.agent-section-box {
  position: absolute;
  right: 0;
  width: 60%;
  left: 40%;
  top: 150px;

  @media (max-width: 1023px) {
    position: relative;
    width: 100%;
    @apply top-60 left-0;
  }
}
.gird-list {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(3, calc(50% - 125px));
  grid-template-rows: minmax(400px, 1fr);

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, calc(80% - 40%));
    grid-template-rows: minmax(auto, 1fr);
  }

  @media (max-width: 640px) {
    grid-template-columns: repeat(3, calc(100% - 40%));
    grid-template-rows: minmax(auto, 1fr);
  }

  @media (min-width: 1600px) {
    grid-template-columns: repeat(3, calc(78% - 40%));
  }

  img {
    @media (min-width: 1600px) {
      width: 100%;
    }
  }
}
