$app_name:hero;
@import "./src/styles/variables";
.custom-control-radio {
  display: flex;
  align-items: center;
  margin-right: 16px;
  position: relative;
  padding-left: 29px;
  cursor: pointer;

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;

    &::after {
      content: "";
      position: absolute;
      left: 8px;
      top: 4px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  input {
    // margin-right: 5px;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: $secondary-color;
    }
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  label {
    margin-top: 4px;
    color: rgba(0, 0, 0, 0.65);
  }
}

.checkmark {
  &::after {
    content: "";
    position: absolute;
    display: none;
  }
}
