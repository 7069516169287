$app_name:hero;
@import "./src/styles/variables";
.custom-control-checkbox {
  display: flex;
  align-items: center;
  margin-right: 16px;
  position: relative;
  padding-left: 29px;
  cursor: pointer;

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  &.small {
    margin-right: 8px;
    padding-left: 25px;

    .checkmark {
      position: absolute;
      top: 5px;
      left: 0;
      height: 15px;
      width: 15px;
      background-color: #eee;
      border-radius: 2px;

      &::after {
        content: "";
        position: absolute;
        left: 6px;
        top: 2px;
        width: 4px;
        height: 8px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    &.checked {
      @apply text-secondary;

      .checkmark {
        @apply block bg-secondary;
      }

      .checkmark::after {
        display: block;
      }
    }
  }

  &.disabled {
    color: $grey-color;
    pointer-events: none;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 4px;

    &::after {
      content: "";
      position: absolute;
      left: 8px;
      top: 4px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &.custom-button-checkbox {
    width: 250px;
    padding: 10px 0 10px 40px;
    border: 1px solid #8484847c;
    background-color: #f1f2f38e;
    border-radius: 4px;
    font-size: 1rem;

    &.active {
      @apply border border-secondary bg-secondary bg-opacity-75;
    }

    .checkmark {
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }

  input {
    // margin-right: 5px;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      @apply bg-primary;
    }
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  label {
    margin-top: 4px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }
}

.checkmark {
  &::after {
    content: "";
    position: absolute;
    display: none;
  }
}
