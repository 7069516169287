$app_name:hero;
@import "./src/styles/variables";

.table-list {
  margin-top: 0.5rem;
  .order-detail-link {
    text-decoration: underline;
    color: #111111;
    font-weight: 500;
  }
  table {
    width: 100%;

    thead {
      tr {
        th {
          font-size: 11px;
          color: $label-color;
          padding: 8px 16px;
          text-align: left;
        }
      }
    }

    tbody {
      tr {
        td {
          background-color: #ffffff;
          border-top: solid 5px #f4f6f8;
          border-bottom: solid 5px #f4f6f8;
          padding: 8px 16px;
          font-size: 12px;
          color: $table-color;
          vertical-align: top;
          transition: all 0.2s ease;

          &.actions {
            vertical-align: middle;
          }

          i.block-icon {
            width: 30px;
            display: inline-block;
          }

          .num-value {
            display: inline-block;
            width: 90px;
            text-align: left;
          }

          .insurer-img {
            width: 30px;
          }

          .cmi-label {
            background-color: rgba($color: #646e78, $alpha: 0.1);
            border-radius: 8px;
            display: inline-block;
            padding: 1px 2px;
            font-size: 10px;
            width: 30px;
            text-align: center;
          }
        }

        // &:hover {
        //   td {
        //     background-color: #e0edfa;
        //   }
        // }
      }
    }
  }

  .bullet {
    background-color: #fefae7;
    display: inline-block;
    padding: 0px 8px;
    border-radius: 25%;
    padding-left: 20px;
    position: relative;

    &::before {
      position: absolute;
      content: "";
      width: 8px;
      height: 8px;
      background-color: #f4d843;
      margin-right: 16px;
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
    }
  }

  .preview-slip {
    display: inline-flex;
    align-items: center;
    color: $primary-color;
    outline: none;
    i {
      font-size: 0.8rem;
      margin-right: 4px;
    }
  }

  .view-order-component {
    // overflow-x: scroll;
    .table-grid {
      min-width: 100%;
      // min-width: 1400px;

      .grid-th {
        display: grid;
        grid-template-columns: 10rem 1fr 2fr 4rem 40px 6rem 8rem 6rem 6rem 6rem 5rem 5rem 14rem 5rem;
        // grid-template-columns: 160px 80px 180px 70px 40px 90px 100px 80px 1fr 1fr 80px 80px 150px 100px;
        font-size: 10px;
        color: $font-secondary;
        & > div {
          padding: 10px 5px;
          //TEST
          // border: 1px solid #ccc;
        }
      }

      .grid-tbody {
        display: grid;
        grid-template-columns: 10rem 1fr 2fr 4rem 40px 6rem 8rem 6rem 6rem 6rem 5rem 5rem 14rem 5rem;
        // grid-template-columns: 160px 80px 180px 70px 40px 90px 100px 80px 1fr 1fr 80px 80px 150px 100px;
        font-size: 12px;
        background-color: #ffffff;
        margin-bottom: 5px;
        color: $font-primary;
        & > div {
          padding: 10px 5px;
          //TEST
          // border: 1px solid #ccc;

          .badges {
            margin-top: 5px;
          }
          .expired_at {
            font-size: 10px;
          }

          img {
            border-radius: 4px;
          }
        }

        &-full {
          width: 100%;
          padding: 10px 5px;
          text-align: center;
          background-color: #ffffff;
        }
      }

      .grid-expand-table {
        width: 100%;
        background-color: #ffffff;
        margin-bottom: 5px;
        margin-top: -4px;
        padding: 10px 5px;

        &-grid-th {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 80px;
          font-size: 12px;
          color: $font-secondary;
          & > div {
            padding: 10px;
          }
        }

        &-grid-tbody {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 80px;
          background-color: #f1f4f6;
          margin-bottom: 5px;
          font-size: 12px;
          & > div {
            padding: 10px;

            img {
              border-radius: 4px;
            }

            button i {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}
