$app_name:hero;
.renew-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9990;
  background-color: rgba($color: #000000, $alpha: 0.8);
  display: flex;
  justify-content: center;
  align-items: center;

  &-content {
    width: 500px;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 32px 16px 16px;
    position: relative;

    button.close {
      position: absolute;
      top: 8px;
      right: 8px;
      outline: none;
    }
  }
}
