$app_name:hero;
@import "./src/styles/variables";
.lead-page {
  padding: 16px;

  .result-box {
    text-align: right;
    margin-top: -8px;
    color: #8a949e;
    font-size: 11px;
    margin-bottom: 1.5rem;
  }

  &-title {
    font-size: 11px;
    color: #848484;
    .title-border {
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 10px;
      color: #000000c1;
    }

    .title-bottom {
      font-size: 12px;
      font-weight: 200;
      color: #949494;
      padding-top: 5px;
      padding-bottom: 20px;
    }
  }

  .filter-area {
    .filter-area-box {
      display: grid;
      grid-template-columns: 300px 300px 280px 1fr;
    }
  }
}
