$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/grid";

.quotationModal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9990;
  background-color: rgba($color: #000000, $alpha: 0.8);
  display: flex;
  align-items: center;
  justify-content: center;

  &-separator {
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 30px 1fr;
    grid-column-gap: 1rem;
    align-items: center;
    color: #b0acac;

    span {
      height: 1px;
      border-top: 1px solid;
      align-self: center;
      color: #dfdcdc;
    }
    hr { margin-bottom: 0; }
  }

  &-download {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;

    input {
      background: #f4f4f4;
      max-width: 125px;
      display: inline-block;
      color: gray;
      padding: 0 1rem;
    }
  }

  &-box {
    position: relative;
    width: 550px;
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
      0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
      0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
      0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);

    .checkSign {
      color: $success-color;
      font-size: 4rem;
    }

    .icon-download {
      font-size: 1.2rem;
      color: $secondary-color;
    }

    .btn-download {
      display: flex;
      justify-content: center;
      background-color: #ffffff;
      border: solid 1px rgba($color: $secondary-color, $alpha: 1);
      padding: 0.4rem 2.5rem;
      border-radius: 4px;
      color: $secondary-color;
      font-size: 0.875rem;
    }

    .button-copy-url {
      background: $grey-color;
      border-radius: 3px;
      padding: 0.4rem 0.5rem;
      font-size: 0.8rem;
      color: $font-primary;
      height: 2.5rem;
    }

    .subTitle {
      font-size: 0.8125rem;
      color: $font-primary;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }

    .btn-payment {
      color: #ffffff;
      background-color: $primary-color;
      border-radius: 3px;
      padding: 0.6rem 1.2rem;
      width: 100%;
    }

    .shopping-cart {
      color: #ffffff;
      font-size: 1.25rem;
      verticle-align: middle;
    }

    a {
      color: $secondary-color;
    }
  }

  &-overlay {
    position: fixed;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: wait;
  }
}
