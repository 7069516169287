$app_name:hero;
@import "./src/styles/variables";
@import "./src/styles/mixin";

.form-select {
    @include input-style;

    &:disabled {
        background-color: rgba($color: #cccccc, $alpha: .1);
        cursor: not-allowed;
    }

    &:focus {
        border-color: $primary-color;
        outline: 0;
        box-shadow: 0 0 0 0.1rem rgba($color: $primary-color, $alpha: .3);
    }

    &.has-error {
        border-color: $error-color;
        &:focus {
            border-color: $error-color;
            outline: 0;
            box-shadow: 0 0 0 0.1rem rgba($color: $error-color, $alpha: .3);
        }
    }

    &-error {
        color: $error-color;
        font-size: 0.8rem;
        display: flex;
        align-items: center;

        & i {
            font-size: 1rem;
            margin-right: 5px;
        }
    }

    // &:hover {
    //     border: 1px solid $primary-color;
    // }
}