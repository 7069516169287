$app_name:hero;
@import "./src/styles/variables";

.credit-card-error {
  .error-details {
    ul.hint-lines {
      list-style-type: none;
      font-size: large;
    }

    ul.info-lines {
      list-style-type: none;
    }
  }

  .error-icon.material-icons {
    color: red;
  }
}
